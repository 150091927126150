import React from 'react';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@widget-builder/src/components/ui';

const ProjectSelector = ({ projects, selected, onChange }) => {
  // Find the currently selected project based on the `selected` prop
  const selectedProject = projects.find(project => project.slug === selected);

  return (
    <Select onValueChange={onChange} value={selected} className="main-nav">
      <SelectTrigger className="w-auto p-0 text-3xl text-gray-700 border-0 border-none md:text-lg font-mslab_700 focus:ring-0">
        <SelectValue>{selectedProject ? selectedProject.title : 'Create a Project'}</SelectValue>
      </SelectTrigger>
      <SelectContent className="text-gray-700 bg-white rounded-none border-slate-100 font-mslab_700">
        <SelectGroup>
          {projects.map(project => (
            <SelectItem
              key={project.slug}
              value={project.slug}
              showCheck={false}
              className="pl-2 text-xl text-left cursor-pointer md:text-sm hover:text-orange-400 focus:text-orange-400 focus:bg-white"
            >
              {project.title}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

export default ProjectSelector;
