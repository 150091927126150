import { createPortal } from "react-dom";
import { useDisableBodyScroll } from "../../hooks/";

const Modal = ({ isOpen, onClose, width, height, children, title }) => {
  useDisableBodyScroll(isOpen);

  if (!isOpen) return null;

  return createPortal(
    <>
      <div
        className={`absolute top-0 left-0 z-50 flex items-center justify-center w-full ${
          height || "h-screen"
        }`}
      >
        <div
          className={`relative ${width || "w-4/5"} ${
            height || "h-screen"
          } text-left bg-white shadow-md`}
        >
          <div className="relative flex flex-col w-full h-full">
            <div className="absolute right-0 top-3">
              <button onClick={onClose}>
                <span className="relative top-0 outline-none right-3">
                  <i className="text-xl text-white fa-regular fa-xmark-large hover:text-orange"></i>
                </span>
              </button>
            </div>
            {title && (
              <div className="px-2 mt-1 mb-2">
                <span className="mr-5 text-xl">{title}</span>
              </div>
            )}
            {children}
          </div>
        </div>
      </div>
      <div className="absolute top-0 left-0 z-0 w-full h-full bg-black opacity-50"></div>
    </>,
    document.querySelector("#modal")
  );
};

export default Modal;
