import React from 'react';
import { NavLink } from 'react-router-dom';
import { Separator } from '@widget-builder/src/components/ui';

const NavigationLinks = ({ onLinkClick }) => (
  <>
    <NavLink
      to="/query"
      onClick={onLinkClick}
      className="text-3xl text-gray-700 md:text-lg hover:text-orange-400 font-mslab_700"
    >
      Query
    </NavLink>
    <Separator orientation="vertical" className="hidden h-8 mx-5 md:inline" />
    <NavLink
      to="/mix"
      onClick={onLinkClick}
      className="text-3xl text-gray-700 md:text-lg hover:text-orange-400 font-mslab_700"
    >
      Mix
    </NavLink>
  </>
);

export default NavigationLinks;
