import useGlobalStore from '/src/stores/globalStore';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../utils/cookies';
import { Button } from '@widget-builder/src/components/ui';

const LogOutButton = () => {
  const { setUser } = useGlobalStore();
  const navigate = useNavigate();

  const handleClick = () => {
    setUser(false);
    logout(navigate);
  };
  return <Button size="md" variant="link" className="no-underline bg-transparent border-0 cursor-pointer text-md hover:bg-transparent hover:text-orange-500 hover:no-underline focus:no-underline" onClick={() => handleClick()}>Log Out</Button>;
};

export default LogOutButton;
