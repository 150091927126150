import React from 'react';
import useWidgetBuilderStore from './../../stores/useWidgetBuilderStore';
import { useReadWidget, useCreateWidget, useUpdateWidget, useDeleteWidget } from '../../api/useWidgetQueries';
import { Button } from './button';
import { X, ArrowUp, ArrowDown } from 'lucide-react';

const WidgetElementsTree = React.memo(({ level = 1 }) => {
  const {
    currentWidgetConfig,
    setCurrentWidgetConfig,

    findAndSetCurrentElementConfig,
    selectedElementId,
    setSelectedElementId,
    // deleteWidgetElement,
    // moveElementUp,
    // moveElementDown,
    setShowLibrary,
  } = useWidgetBuilderStore(state => state);

  const updateWidgetMutation = useUpdateWidget();

  const deleteElementById = (config, idToRemove) => {
    if (!config) return null;

    if (config.children && Array.isArray(config.children)) {
      // Recursively process each child and filter out the one to delete
      config.children = config.children
        .map(child => deleteElementById(child, idToRemove))
        .filter(child => child !== null);
    }

    if (config.id === idToRemove) {
      return null;
    }

    return config;
  };

  const handleDelete = id => {
    const updatedConfig = deleteElementById(currentWidgetConfig, id);
    updateWidgetMutation.mutate({
      widgetID: currentWidgetConfig.id,
      input: updatedConfig,
    });

    setCurrentWidgetConfig(updatedConfig);
  };

  const moveElementDown = (config, id) => {
    if (!config || !id) {
      console.error('currentWidgetConfig or id is undefined');
      return config;
    }

    const moveElementDownRecursive = (elements, parent) => {
      for (let i = 0; i < elements.length; i++) {
        // Check within current level
        if (elements[i].id === id && i < elements.length - 1) {
          // Swap with the next sibling
          [elements[i], elements[i + 1]] = [elements[i + 1], elements[i]];
          return true; // Stop further traversal
        }

        // Recurse into children
        if (elements[i].children && Array.isArray(elements[i].children)) {
          const result = moveElementDownRecursive(elements[i].children, elements[i]);
          if (result) return true; // Stop further traversal if found
        }
      }
      return false; // Element not found in this branch
    };

    const newConfig = JSON.parse(JSON.stringify(config));

    if (newConfig.children) {
      moveElementDownRecursive(newConfig.children, null);
    }

    return newConfig;
  };

  const handleMoveDown = id => {
    if (!id) {
      console.error('No element selected to move down.');
      return;
    }

    const updatedConfig = moveElementDown(currentWidgetConfig, id);

    if (updatedConfig) {
      setCurrentWidgetConfig(updatedConfig);
      updateWidgetMutation.mutate({
        widgetID: currentWidgetConfig.id,
        input: updatedConfig,
      });
    }
  };

  const moveElementUp = (config, selectedElementId) => {
    if (!config || !selectedElementId) {
      console.error('currentWidgetConfig or selectedElementId is undefined');
      return config;
    }

    const moveElementUpRecursive = (elements, parent) => {
      for (let i = 0; i < elements.length; i++) {
        if (elements[i].id === selectedElementId && i > 0) {
          [elements[i - 1], elements[i]] = [elements[i], elements[i - 1]];
          return true;
        }

        if (elements[i].children && Array.isArray(elements[i].children)) {
          const result = moveElementUpRecursive(elements[i].children, elements[i]);
          if (result) return true;
        }
      }
      return false;
    };

    const newConfig = JSON.parse(JSON.stringify(config));

    if (newConfig.children) {
      moveElementUpRecursive(newConfig.children, null);
    }

    return newConfig;
  };

  const handleMoveUp = id => {
    if (!id) {
      console.error('No element selected to move up.');
      return;
    }

    const updatedConfig = moveElementUp(currentWidgetConfig, id);

    if (updatedConfig) {
      setCurrentWidgetConfig(updatedConfig);
      updateWidgetMutation.mutate({
        widgetID: currentWidgetConfig.id,
        input: updatedConfig,
      });
    }
  };

  const handleSelect = React.useCallback(
    id => {
      if (selectedElementId === id) {
        setSelectedElementId(null);
        findAndSetCurrentElementConfig(null);
        setShowLibrary(false); // Hide library when deselecting
      } else {
        setSelectedElementId(id);
        findAndSetCurrentElementConfig(id);
        
        const findElement = (config) => {
          if (config.id === id) return config;
          if (config.children) {
            for (const child of config.children) {
              const found = findElement(child);
              if (found) return found;
            }
          }
          return null;
        };

        const selectedElement = findElement(currentWidgetConfig);
        
        // Show library for both types of containers
        setShowLibrary(
          (selectedElement?.component === "Container" && selectedElement?.type === "layout") ||
          (selectedElement?.name === "Container" && selectedElement?.type === "widget-container")
        );
      }
    },
    [findAndSetCurrentElementConfig, setSelectedElementId, selectedElementId, currentWidgetConfig, setShowLibrary]
  );

  const renderTree = React.useCallback(
    (config, currentLevel) => {
      if (!config) return null;

      // Skip rendering the root item (or first item at level 0)
      if (currentLevel === 0) {
        return (
          <>
            {config.children?.map(child => (
              <React.Fragment key={child.id || child.component}>{renderTree(child, currentLevel + 1)}</React.Fragment>
            ))}
          </>
        );
      }

      const indent = currentLevel * 8;
      const isSelected = selectedElementId === config.id;

      return (
        <>
          <li
            style={{ paddingLeft: `${indent}px` }}
            className={`py-1 group hover:bg-slate-100 ${isSelected ? 'bg-slate-100' : ''}`}
          >
            <div className="inline-flex items-center">
              <span
                className={`text-md cursor-pointer ${isSelected ? 'text-orange-500' : ''}`}
                onClick={() => handleSelect(config.id)}
              >
                {config.name || config.component || config.id}
              </span>

              <span className={`ml-1 ${isSelected ? 'visible' : 'invisible'}`}>
                <Button className="ml-2 mr-1" variant="outline" size="xs" onClick={() => handleDelete(config.id)}>
                  <X className="w-4 h-4" />
                </Button>
                <Button className="mr-1" variant="outline" size="xs" onClick={() => handleMoveUp(config.id)}>
                  <ArrowUp className="w-4 h-4" />
                </Button>
                <Button variant="outline" size="xs" onClick={() => handleMoveDown(config.id)}>
                  <ArrowDown className="w-4 h-4" />
                </Button>
              </span>
            </div>
          </li>
          {config.children?.map(child => (
            <React.Fragment key={child.id || child.component}>{renderTree(child, currentLevel + 1)}</React.Fragment>
          ))}
        </>
      );
    },
    [handleDelete, handleMoveDown, handleMoveUp, handleSelect, selectedElementId]
  );

  if (Object.keys(currentWidgetConfig).length === 0) return null;

  return <ul>{renderTree(currentWidgetConfig, level)}</ul>;
});

export { WidgetElementsTree };
