import { useEffect, startTransition, Suspense } from 'react';
import { Outlet, Navigate, useParams, useNavigate, useLocation } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import { getDashboard } from '@dashboard/api/DashboardApi';
import useGlobalStore from '@dashboard/stores/globalStore';
import { GetActiveProjectObject, GetActiveBoardObject } from '@dashboard/utils/dashboardUtils';

import { Nav } from '@dashboard/components/Header';
import { Skeleton } from '@widget-builder/src/components/ui';

const LoadingSkeleton = () => {
  return (
    <div className="container relative max-w-screen-xl px-5 py-2 mx-auto bg-white">
      <div className="w-full space-y-4">
        {/* Nav skeleton */}
        <div className="flex items-center justify-between p-4">
          <Skeleton className="h-8 w-[200px]" />
          <div className="flex space-x-2">
            <Skeleton className="h-8 w-[100px]" />
            <Skeleton className="h-8 w-[100px]" />
          </div>
        </div>

        {/* Content skeleton */}
        <div className="p-4 space-y-4">
          <Skeleton className="h-[200px] w-full" />
          <div className="grid grid-cols-2 gap-4">
            <Skeleton className="h-[100px] w-full" />
            <Skeleton className="h-[100px] w-full" />
          </div>
        </div>
      </div>
    </div>
  );
};

const App = () => {
  const location = useLocation();
  const { setCurrentProjectObj, setCurrentBoardObj } = useGlobalStore();
  const navigate = useNavigate();
  const params = useParams();

  const { data, isLoading } = useQuery({
    queryKey: ['dashboard-config'],
    queryFn: getDashboard,
    options: {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      suspense: true,
    },
  });

  useEffect(() => {
    if (!isLoading && data) {
      const currentProjectObject = GetActiveProjectObject(data);
      const currentBoardObject = GetActiveBoardObject(data);

      if (currentProjectObject && currentBoardObject) {
        startTransition(() => {
          setCurrentProjectObj(currentProjectObject);
          setCurrentBoardObj(currentBoardObject);
        });

        if (location.pathname === '/') {
          const currentProjectBoardUrl = `/${currentProjectObject.slug}/${currentBoardObject.slug}`;
          startTransition(() => {
            navigate(currentProjectBoardUrl);
          });
        }
      } else if (location.pathname !== '/fallback') {
        startTransition(() => {
          navigate('/fallback');
        });
      }
    }
  }, [isLoading, data, location.pathname, navigate, setCurrentProjectObj, setCurrentBoardObj]);

  const Authenticated = () => {
    const { user } = useGlobalStore();
    return window.localStorage.getItem('token') || user.token ? (
      <>
        <Nav />
        <Outlet />
      </>
    ) : (
      <Navigate to="/login" />
    );
  };

  if (isLoading) {
    return <LoadingSkeleton />;
  }

  return (
    <Suspense fallback={<LoadingSkeleton />}>
      <Authenticated />
    </Suspense>
  );
};

export default App;
