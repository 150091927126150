import axios from 'axios';
import { QUERY_TENANTS } from './../../GraphQL';

const tenantID = window.localStorage.getItem('defaultTenantID');

const QueryTenants = async () => {
  try {
    const response = await axios.post(import.meta.env.VITE_APP_GRAPHQL_URI, {
      query: QUERY_TENANTS,
    });

    return response.data.data;
  } catch (error) {
    console.error('Error fetching tenants:', error);
    throw new Error('Failed to fetch tenants');
  }
};

export default QueryTenants;
