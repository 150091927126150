import React, { useState, useMemo } from 'react';

import { X } from 'lucide-react';
import { useQuery } from '@tanstack/react-query';
import QueryTenants from '/src/components/Services/Tenants/QueryTenants';
import { Skeleton } from '@widget-builder/src/components/ui';

function TenantsMenu({ isOpen, onClose }) {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ['tenants'],
    queryFn: QueryTenants,
    enabled: isOpen,
  });

  const tenantsData = data?.tenants || [];
  const [searchTerm, setSearchTerm] = useState('');

  const filteredTenants = useMemo(() => {
    return searchTerm
      ? tenantsData
          .filter(tenant => tenant.name.toLowerCase().includes(searchTerm.toLowerCase()))
          .sort((a, b) => a.name.localeCompare(b.name))
      : tenantsData.sort((a, b) => a.name.localeCompare(b.name));
  }, [searchTerm, tenantsData]);

  const handleTenantClick = tenant => {
    window.localStorage.setItem('defaultTenantID', tenant.id);
    window.localStorage.setItem('defaultTenantSlug', tenant.slug);
    window.location.href = '/';
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex flex-col p-5 overflow-y-auto bg-white">
      <div className="flex justify-end">
        <button
          onClick={onClose}
          className="text-white bg-gray-400 cursor-pointer hover:text-gray-600 hover:text-black focus:outline-none"
          aria-label="Close menu"
        >
          <X className="w-8 h-8" />
        </button>
      </div>

      <div className="mb-6">
        <input
          type="text"
          autoFocus
          className="w-full px-2 py-3 text-2xl text-gray-700 placeholder-gray-500 bg-transparent border-t-0 border-l-0 border-r-0 border-b-gray-200 focus:border-b-black focus:outline-none focus:ring-0 font-mslab_700"
          placeholder="Type to search tenants..."
          onChange={e => setSearchTerm(e.target.value)}
        />
      </div>

      <div className="grid grid-cols-2 gap-4 mt-4 md:grid-cols-6 xl:grid-cols-8">
        {isLoading ? (
          Array.from({ length: 24 }).map((_, index) => <Skeleton key={index} className="w-full h-24" />)
        ) : isError ? (
          <div className="text-red-500">Error loading tenants: {error.message}</div>
        ) : filteredTenants.length > 0 ? (
          filteredTenants.map(tenant => (
            <div 
              key={tenant.id} 
              className="relative flex flex-col items-center h-32 p-4 border border-gray-200 rounded-md shadow-sm hover:bg-gray-100"
            >
              <h3 className="absolute font-semibold text-center w-[90%] text-md">
                {tenant.name}
              </h3>
              <button
                onClick={() => handleTenantClick(tenant)}
                className="absolute px-4 py-1 text-sm text-white bg-gray-400 border-0 rounded cursor-pointer bottom-4 left-4 right-4 hover:bg-gray-700"
              >
                Switch
              </button>
            </div>
          ))
        ) : (
          <div className="text-gray-500">No tenants found.</div>
        )}
      </div>
    </div>
  );
}

export default TenantsMenu;
