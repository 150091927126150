import clsx from 'clsx';
import { twMerge } from 'tailwind-merge';

import {
  TrendingUp,
  BarChart,
  LineChart,
  AreaChart,
  PieChart,
  Columns,
  Type,
  X,
  LayoutGrid,
  Pilcrow,
  ArrowUpDown,
  Table,
} from 'lucide-react';

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

const iconMap = {
  TrendingUp: TrendingUp,
  BarChart: BarChart,
  LineChart: LineChart,
  AreaChart: AreaChart,
  PieChart: PieChart,
  Columns: Columns,
  Type: Type,
  Pilcrow: Pilcrow,
  ArrowUpDown: ArrowUpDown,
  LayoutGrid: LayoutGrid,
  X: X,
  Table: Table,
};

export const getIcon = iconName => {
  return iconMap[iconName] || null;
};

export const splitCamelCaseToString = text => {
  return text.replace(/([A-Z])/g, ' $1').trim();
};

export const isEmptyObject = obj => {
  return Object.keys(obj).length === 0 && obj.constructor === Object;
};

const defaultColorPalette = [
  '#fc6d52',
  '#fcd239',
  '#15c8de',
  '#15de85',
  '#f3108e',
  '#87c544',
  '#b85a5c',
  '#86c499',
  '#c164a5',
  '#757ec9',
  '#c08e4a',
  '#9f50d4',
  '#607e3f',
  '#c9b0bb',
  '#65757d',
];

const rgbToHsl = (r, g, b) => {
  (r /= 255), (g /= 255), (b /= 255);
  const max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h,
    s,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return [h, s, l];
};

const hslToRgb = (h, s, l) => {
  let r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
};

const adjustColor = (color, brightness = 0, saturation = 0) => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  // Apply brightness
  R = Math.min(255, R + brightness);
  G = Math.min(255, G + brightness);
  B = Math.min(255, B + brightness);

  // Convert to HSL, adjust saturation, and convert back to RGB
  let [h, s, l] = rgbToHsl(R, G, B);
  s = Math.min(1, Math.max(0, s + saturation));
  [R, G, B] = hslToRgb(h, s, l);

  return `#${R.toString(16).padStart(2, '0')}${G.toString(16).padStart(2, '0')}${B.toString(16).padStart(2, '0')}`;
};

const checkContrast = (color1, color2) => {
  // TODO: improve
  return color1 !== color2;
};

export const generateColors = dataLength => {
  let barColors = [];
  let lastColor = '';

  for (let i = 0; i < dataLength; i++) {
    let baseColor = defaultColorPalette[i % defaultColorPalette.length];
    let adjustedColor = adjustColor(
      baseColor,
      10 * (i / defaultColorPalette.length),
      0.1 * (i / defaultColorPalette.length)
    );

    if (i >= defaultColorPalette.length && !checkContrast(adjustedColor, lastColor)) {
      adjustedColor = adjustColor(adjustedColor, 20, 0.2);
    }

    lastColor = adjustedColor;
    barColors.push(adjustedColor);
  }

  return barColors;
};

export function isEditMode() {
  return window.location.href.includes('/builder/');
}
