import axios from 'axios';
import * as queries from '/src/components/GraphQL';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const widgetCreate = async ({ ...variables }) => {
  const response = await axios.post(import.meta.env.VITE_APP_GRAPHQL_URI, {
    query: queries.WIDGET_CREATE,
    variables: {
      tenantID: +window.localStorage.getItem('defaultTenantID'),
      ...variables,
    },
  });

  if (response.status !== 200) {
    throw new Error('Error');
  }

  return response.data.data;
};

export const widgetUpdate = async ({ ...variables }) => {
  const response = await axios.post(import.meta.env.VITE_APP_GRAPHQL_URI, {
    query: queries.WIDGET_UPDATE,
    variables: {
      tenantID: +window.localStorage.getItem('defaultTenantID'),
      ...variables,
    },
  });

  if (response.status !== 200) {
    throw new Error('Error');
  }

  return response.data.data;
};

export const widgetDelete = async widgetID => {
  const response = await axios.post(import.meta.env.VITE_APP_GRAPHQL_URI, {
    query: queries.WIDGET_DELETE,
    variables: {
      tenantID: +window.localStorage.getItem('defaultTenantID'),
      widgetID: widgetID,
    },
  });

  if (response.status !== 200) {
    throw new Error('Error');
  }

  return response.data.data;
};

export const getWidgetQueries = async ({ ...variables }) => {
  const response = await axios.post(import.meta.env.VITE_APP_GRAPHQL_URI, {
    query: queries.WIDGET_QUERIES,
    variables: {
      tenantID: +window.localStorage.getItem('defaultTenantID'),
      projectID: +window.localStorage.getItem('currentProjectId'),
      ...variables,
    },
  });

  if (response.status !== 200) {
    toast.error("Couldn't fetch widget's data", {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    throw new Error('Error');
  }

  return response.data.data;
};
