import React, { Component, PureComponent, Fragment, useState } from "react";
import ReactTooltip from "react-tooltip";
import Responsive from "react-responsive";
import { Link } from "react-router-dom";
import numeral from "numeraljs";
import moment from "moment";
import Uri from "jsuri";
import clsx from "clsx";
import _uniqueId from "lodash/uniqueId";

import { loadDataRequest } from "./../actions";
import { ColorPalette, defaultColors } from "./ColorPalette";
import * as Sentry from "@sentry/react";

import {
  BarChart,
  Bar,
  Tooltip,
  XAxis,
  YAxis,
  LineChart,
  Line,
  Cell,
  Text,
  ResponsiveContainer,
} from "recharts";

import {
  FaFacebookSquare,
  FaTwitterSquare,
  FaInstagram,
  FaYoutube,
  FaSnapchat,
  FaTiktok,
} from "react-icons/fa";

import youtube_icon from "../i/icons/youtube.svg";
import facebook_icon from "../i/icons/facebook.svg";
import twitter_icon from "../i/icons/twitter.svg";
import instagram_icon from "../i/icons/instagram.svg";
import snapchat_icon from "../i/icons/snapchat.svg";
import parsely_icon from "../i/icons/parsely.svg";
import bigquery_icon from "../i/icons/bigquery.svg";
import snowflake_icon from "../i/icons/snowflake.svg";
import tiktok_icon from "../i/icons/tiktok.svg";

import A_score from "../i/icons/a.svg";
import B_score from "../i/icons/b.svg";
import C_score from "../i/icons/c.svg";

import missing_image from "../i/missing_image.svg";
import Heading from "../elements/Heading";
import { isEqual, differenceWith } from "lodash";
import flags from "./FlagsHashmap";

const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const getUTCDate = (dateString = Date.now(), format) => {
  let date = new Date(dateString);

  if (format === "MM/DD") {
    const parts = dateString.split("/");
    date = new Date();
    date.setUTCMonth(parseInt(parts[0], 10) - 1);
    date.setUTCDate(parseInt(parts[1], 10));
  } else if (format === "MMM") {
    const idx = MONTHS.indexOf(dateString);
    date = new Date();
    if (idx >= date.getUTCMonth()) {
      date.setUTCFullYear(date.getUTCFullYear() - 1);
    }
    date.setUTCMonth(idx);
  } else if (format === "MM/DD hh:mm") {
    const [datePart, timePart] = dateString.split(" ");
    const [month, day] = datePart.split("/");
    const [hour, minute] = timePart.split(":");
    date = new Date();
    date.setUTCMonth(parseInt(month, 10) - 1);
    date.setUTCDate(parseInt(day, 10));
    date.setUTCHours(parseInt(hour, 10));
    date.setUTCMinutes(parseInt(minute, 10));
  }

  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
};

export const truncate = (string, chars) => {
  if (string && string.length > chars)
    return string.substring(0, chars) + "...";
  else return string;
};

/**
 * Truncates decimals avoiding them to get it rounded (even using `toFixed()`)
 * @param {*} num Value to be truncated
 * @param {*} dec how many decimals are going to be truncated
 */
export const truncateToDecimals = (num, dec = 2) => {
  const calcDec = Math.pow(10, dec);
  return Math.trunc(num * calcDec) / calcDec;
};

/**
 * Formats the date from ISO Format to a specific format used in widgets;
 * If the period of the range is greater than one year, so, the format will add the year, if not, it will only
 * contain the MM/DD
 * @param {*} data
 * @param {*} formatDataGreatThanYear If defined, the format for date on data range greater than one year will be set.
 */
export const formatDateFromData = (
  data,
  formatDataGreatThanYear = null,
  fieldName = null
) => {
  if (data.length > 0) {
    // gets the first and the last element to check its dates
    const firstElement = data[0];
    const lastElement = data[data.length - 1];
    let firstDate =
      firstElement.full_date || firstElement.date || firstElement[fieldName];
    let lastDate =
      lastElement.full_date || lastElement.date || lastElement[fieldName];
    // determines whether the date from each elements inside data is an object or not
    let isDateObject = false;

    // in some cases, the date is an object, so we should treat it as well
    if (firstDate && typeof firstDate === "object") {
      isDateObject = true;
      firstDate = firstDate.full_date || firstDate.date || firstDate[fieldName];
      lastDate = lastDate.full_date || lastDate.date || lastDate[fieldName];
    }

    // checks if the diff between the lastDate and first date is greater in years
    const isGreaterThanYear =
      isIsoDate(firstDate) &&
      moment(lastDate).diff(moment(firstDate), "years") > 0;
    return data.map((e) => {
      // full_date is gonna be replaced by .date in the future and it actually is in ISO format
      // because date may be an object.. we replace it properly according the nested level
      const date = isDateObject
        ? e.date.full_date || e.date.date || e.date[fieldName]
        : e.full_date || e.date || e[fieldName];

      // is date in ISO format? Format(if the range of data is greater than a year, change the format), if not, keep the pure value
      const formattedDate = isIsoDate(date)
        ? moment(date)
            .utc()
            .format(
              isGreaterThanYear ? formatDataGreatThanYear || "MM/YYYY" : "MM/DD"
            )
        : date;

      let newElement = {
        ...e,
        // if date is object, set a new object with all its properties.. if not, set just the date
        date: isDateObject ? { ...e.date, date: formattedDate } : formattedDate,
      };

      if (fieldName) newElement[fieldName] = formattedDate;

      return newElement;
    });
  }
};

/**
 * Checks whether a given string is in ISO 8601 date format.
 * @param {string} str - String to check.
 * @returns {boolean} - True if the string is in ISO format, false otherwise.
 */
export const isIsoDate = (str) => {
  // Ensure the string includes milliseconds by adding '.000' if not present
  str = str.replace("Z", ".000Z");

  // Regular expression to validate the ISO 8601 date format
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;

  // Parse and reformat the date to ensure it matches the original string
  const parsedDate = new Date(str);
  return parsedDate.toISOString() === str;
};

/**
 * Checks if the date in the first element of data is in ISO format.
 * @param {Array} data - Data to check.
 * @returns {boolean} - True if date is in ISO format, false otherwise.
 */
export const isDateFromDataInISOFormat = (data) => {
  if (!Array.isArray(data) || data.length === 0) return false;

  // Function to extract date value from an element
  const getDateValue = (element) => {
    const dateValue = element.full_date || element.date;
    return typeof dateValue === "object"
      ? dateValue.full_date || dateValue.date
      : dateValue;
  };

  return isIsoDate(getDateValue(data[0]));
};

export const capitalizeFirstLetter = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const capitalizeKey = (string, dilimiter = "_") =>
  string.split(dilimiter).map(capitalizeFirstLetter).join(" ");

export const selectFormat = (type, value, format) => {
  switch (type) {
    case "date":
      return moment(value, ["MM/DD", "YYYY-MM-DD"]).format(
        format || "YYYY-MM-DD"
      );

    case "time":
      return value;

    case "minutes":
      return numeral(value).format(format ? format : "0a") + " min";
    case "sec":
      return numeral(value).format(format ? format : "0a") + " sec";
    case "minsec":
      return moment(value * 1000).format("mm:ss");

    case "percentage":
      let percent_format = "";

      if (Math.sign(value) === 1) {
        if (value <= 0.01 && value > 0) {
          percent_format = "0.00%";
        } else if (value > 0.01 && value < 0.1) {
          percent_format = "0.0%";
        } else if (value >= 0.1) {
          percent_format = "0%";
        } else {
        }
      } else {
        if (value >= -0.01 && value < 0) {
          percent_format = "0.00%";
        } else if (value < -0.01 && value > -0.1) {
          percent_format = "0.0%";
        } else if (value <= -0.1) {
          percent_format = "0%";
        } else {
        }
      }

      return numeral(value).format(format ? format : percent_format);
    case "currency":
      return value >= 1000
        ? numeral(value).format(format ? format : "$0.0a")
        : numeral(value).format(format ? format : "$0.00");
    case "currency-GB":
      return (
        "£" +
        (value >= 1000
          ? numeral(value).format(format ? format : "0.0a")
          : numeral(value).format(format ? format : "0.00"))
      );
    default:
      return value >= 999
        ? numeral(value).format(format ? format : "0.0a")
        : numeral(value).format(format ? format : "0a");
  }
};

export class ComponentWithUrl extends Component {
  componentDidMount() {
    const { dispatch, url } = this.props;
    dispatch(loadDataRequest({ url }));
  }

  componentDidUpdate(nextProps) {
    const { dispatch, url } = this.props;

    if (nextProps.url !== url) {
      dispatch(loadDataRequest({ url: nextProps.url }));
    }
  }
}

export const sortNumeric = (a, b, order) => {
  let A, B;

  if (a && a.value !== "undefined" && a.value !== 0 && a.value !== "N/A") {
    A = a.value;
  } else {
    A = -1;
  }

  if (b && b.value !== "undefined" && b.value !== 0 && b.value !== "N/A") {
    B = b.value;
  } else {
    B = -1;
  }

  if (order === "desc") {
    return B - A;
  } else {
    return A - B;
  }
};

export const sortDate = (a, b, order) => {
  if (order === "desc") {
    return new Date(b.date) - new Date(a.date);
  } else {
    return new Date(a.date) - new Date(b.date);
  }
};

export const TableContentTitle = (cell, row, board) => {
  // Title
  let TITLE = "";
  if (row.title !== null) {
    TITLE = <h1 className="content-table__title">{truncate(row.title, 50)}</h1>;
  } else if (row.tweet_text) {
    TITLE = (
      <h1 className="content-table__title">{truncate(row.tweet_text, 50)}</h1>
    );
  } else {
    TITLE = "";
  }

  return (
    <article className="content-table">
      <section className="content-table__detail">{TITLE}</section>
    </article>
  );
};

export const TableContentCell = (cell, row, rowIndex, opts) => {
  if (opts?.showAsLink) {
    return (
      <a href={row.url} target="_blank" rel="noopener noreferrer">
        {opts.defaultTitle || truncate(row.title, 50)}
      </a>
    );
  }

  // Icon
  const ICON = row.network ? renderIcons([row.network], "sm") : false;
  const ICON_SRC = ICON ? ICON[0].props.src : "";

  // Image
  let IMAGE = { backgroundImage: `url(${missing_image})` };
  if (row.image_url === null || row.image_url === undefined) {
    IMAGE = { backgroundImage: `url(${missing_image})` };
  } else {
    IMAGE = {
      backgroundImage: `url(${row.image_url.replace(/'/g, "%27")})`,
    };
  }

  // Series
  let SERIES = null;
  if (row.series_title !== null) {
    SERIES = <h2 className="content-table__subtitle">{row.series_title}</h2>;
  }

  // Url
  let URL = row.url;
  let EXTERNAL_URL = true;

  // Title
  let TITLE = "";
  let rowTitle = row.title || row.video_title || row.block_title;
  if (rowTitle !== null && rowTitle !== "") {
    TITLE = <h1 className="content-table__title">{truncate(rowTitle, 50)}</h1>;
  } else if (row.tweet_text) {
    TITLE = (
      <h1 className="content-table__title">{truncate(row.tweet_text, 50)}</h1>
    );
  } else {
    TITLE = "";
  }

  const PUB_DATE = row.date_pub;
  const DURATION = row.duration;

  return (
    <article className="content-table">
      <figure className="content-table__figure">
        {ICON && (
          <span className="content-table__icon">
            <img src={ICON_SRC} alt="Icon" />
          </span>
        )}

        {URL ? (
          EXTERNAL_URL ? (
            <a href={URL} target="_blank" rel="noopener noreferrer">
              <div className="content-table__img" style={IMAGE} />
            </a>
          ) : (
            <Link to={URL}>
              <div className="content-table__img" style={IMAGE} />
            </Link>
          )
        ) : (
          <div className="content-table__img" style={IMAGE} />
        )}
      </figure>

      <section className="content-table__detail">
        {URL ? (
          EXTERNAL_URL ? (
            <a href={URL} target="_blank" rel="noopener noreferrer">
              {TITLE}
            </a>
          ) : (
            <Link to={URL}>{TITLE}</Link>
          )
        ) : (
          TITLE
        )}

        {SERIES}

        {opts && opts.showDate ? (
          <time className="content-table__date">
            {PUB_DATE ? PUB_DATE : ""}
            {DURATION ? (
              <span className="content-table__duration">
                {" "}
                <span className="content-table__duration-text">dur:</span>{" "}
                {DURATION}
              </span>
            ) : (
              ""
            )}
          </time>
        ) : null}
      </section>
    </article>
  );
};

export const TableContentColoredIcon = (
  cell,
  row,
  rowIndex,
  opts,
  iconColor
) => {
  return (
    <span className="mt-2 legend mt-md-0">
      <span
        className="legend__icon"
        style={{ backgroundColor: iconColor || MAIN_COLORS[rowIndex] }}
      ></span>
      {truncate(row.title, 50)}
    </span>
  );
};

export class CustomizedXAxisTick extends PureComponent {
  render() {
    const { x, y, payload } = this.props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={15}
          textAnchor="middle"
          style={{ fontSize: 12 }}
          fill="#979797"
        >
          {selectFormat("number", payload.value)}
        </text>
      </g>
    );
  }
}

export class CustomizedYAxisLabel extends PureComponent {
  render() {
    const { x, y, payload, fill } = this.props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          width={100}
          textAnchor="end"
          style={{ fontSize: 12 }}
          fill={fill}
        >
          {payload.value.split("|")[0]}
        </text>
      </g>
    );
  }
}

export class CustomizedYAxisTickPercent extends PureComponent {
  render() {
    const { x, y, payload } = this.props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={15}
          y={15}
          textAnchor="middle"
          style={{ fontSize: 12 }}
          fill="#979797"
        >
          {selectFormat("percentage", payload.value)}
        </text>
      </g>
    );
  }
}

export class CustomizedYAxisTick extends PureComponent {
  render() {
    const { x, y, payload, fill, formatType, formatMask } = this.props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={5}
          textAnchor="start"
          style={{ fontSize: 12 }}
          fill={fill}
        >
          {selectFormat(formatType || "number", payload.value, formatMask)}
        </text>
      </g>
    );
  }
}

export class CustomizedYAxisTickSeconds extends PureComponent {
  render() {
    const { x, y, payload, fill } = this.props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={5}
          textAnchor="start"
          style={{ fontSize: 12 }}
          fill={fill}
        >
          {selectFormat("sec", payload.value)}
        </text>
      </g>
    );
  }
}

export class CustomizedYAxisTickLeftCurrency extends PureComponent {
  render() {
    const { x, y, payload, fill } = this.props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={5}
          textAnchor="start"
          style={{ fontSize: 12 }}
          fill={fill}
        >
          {selectFormat("currency", payload.value)}
        </text>
      </g>
    );
  }
}

export class CustomizedYAxisTickRightCurrency extends PureComponent {
  render() {
    const { x, y, payload, fill } = this.props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={-40}
          y={5}
          textAnchor="start"
          style={{ fontSize: 12 }}
          fill={fill}
        >
          {selectFormat("currency", payload.value)}
        </text>
      </g>
    );
  }
}

export class CustomizedYAxisTickRight extends PureComponent {
  render() {
    const { x, y, payload, fill } = this.props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={-10}
          y={5}
          textAnchor="middle"
          style={{ fontSize: 12 }}
          fill={fill}
        >
          {selectFormat("number", payload.value)}
        </text>
      </g>
    );
  }
}

export class CustomizedYAxisTickRightPercent extends PureComponent {
  render() {
    const { x, y, payload, fill } = this.props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={-10}
          y={5}
          textAnchor="middle"
          style={{ fontSize: 12 }}
          fill={fill}
        >
          {selectFormat("percent", payload.value, "%")}
        </text>
      </g>
    );
  }
}

export class CustomizedYAxisTickRightSeconds extends PureComponent {
  render() {
    const { x, y, payload, fill } = this.props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={5} textAnchor="end" style={{ fontSize: 12 }} fill={fill}>
          {selectFormat("sec", payload.value)}
        </text>
      </g>
    );
  }
}

export class CustomizedXAxisTickLongText extends PureComponent {
  render() {
    const { x, y, payload } = this.props;
    return (
      <g transform={`translate(${x},${y})`}>
        <Text
          x={0}
          y={0}
          width={110}
          textAnchor="middle"
          verticalAnchor="start"
          style={{ fontSize: 12 }}
          fill="#979797"
        >
          {payload.value}
        </Text>
      </g>
    );
  }
}

export class CustomizedXAxisTickTime extends PureComponent {
  render() {
    const { x, y, payload } = this.props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={15}
          textAnchor="middle"
          style={{ fontSize: 12 }}
          fill="#979797"
        >
          {payload.value}
        </text>
      </g>
    );
  }
}

export class CustomizedXAxisTickTimeDay extends PureComponent {
  render() {
    const { x, y, payload } = this.props;

    return (
      <svg>
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={15}
            textAnchor="middle"
            style={{ fontSize: 12 }}
            fill="#979797"
          >
            {payload.value.replace(/:00/, "")}
          </text>
        </g>
      </svg>
    );
  }
}

export class CustomizedYAxisTickPercentage extends PureComponent {
  render() {
    const { x, y, payload } = this.props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={15}
          y={0}
          textAnchor="middle"
          style={{ fontSize: 12 }}
          fill="#3D3D3D"
        >
          {selectFormat("percentage", payload.value)}
        </text>
      </g>
    );
  }
}

export class CustomizedXAxisTickSingleDay extends PureComponent {
  render() {
    const { x, y, payload } = this.props;

    const date = new Date(payload.value);
    const day = date.getDate();
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={10}
          textAnchor="middle"
          style={{ fontSize: 12 }}
          fill="#3D3D3D"
        >
          {day}
        </text>
      </g>
    );
  }
}

export class CustomizedXAxisTickShortDate extends PureComponent {
  render() {
    const { x, y, payload } = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={10}
          textAnchor="middle"
          style={{ fontSize: 12 }}
          fill="#3D3D3D"
        >
          {moment(payload.value).utc().format("MM/DD")}
        </text>
      </g>
    );
  }
}

export class CustomMetricToolTip extends PureComponent {
  showLabels = (payload, type = "number") => {
    let labels = Object.keys(payload).map(function (key) {
      // Determine the format based on the type
      let formatType;
      let format;
      switch (type) {
        case "time":
          formatType = "time";
          format = "";
          break;
        case "percentage":
          formatType = "percentage";
          format = "%0.0";
          break;
        default:
          formatType = "number"; // Default to number if type is not specified or recognized
      }

      return (
        <p className="value" key={key}>
          {selectFormat(
            formatType,
            type === "time"
              ? payload[key].payload.displayedValue
              : payload[key].value,
            format
          )}
          <br /> <span className="label">{payload[key].payload.label}</span>
        </p>
      );
    });

    return labels;
  };

  render() {
    const { active } = this.props;

    if (active) {
      const { payload, type } = this.props;
      if (payload && payload.length > 0) {
        return (
          <div className="custom-mini-tooltip">
            {this.showLabels(payload, type)}
          </div>
        );
      }
    }

    return null;
  }
}

export class StandardTooltip extends PureComponent {
  data = (props) => {
    const { active } = this.props;

    if (active) {
      const { payload } = this.props;
      if (payload && payload.length > 0) {
        return payload;
      }
    }

    return null;
  };

  render = () => <span>{this.props.render({ data: this.data })}</span>;
}

// Types: numeral, currency, percentage, time
export const Metric = (props) => {
  const {
    value,
    change,
    direction,
    size,
    type,
    graph,
    format,
    icon,
    showGraphOnly,
    hidePrimaryValue,
    graphOpts,
    widgetClassName,
    chartTrendClassName,
    comparison,
    isPositive,
    showPureValue = false,
  } = props;

  const widget_size =
    widgetClassName || (size ? `metric--${size} align-items-start` : "");
  const widget_type = type ? `metric--${type} align-items-start` : "";
  const chartTrendContainerStyle = clsx(
    chartTrendClassName || "d-flex flex-column ml-2",
    {
      "mt-auto mb-1": graph && !change,
    }
  );

  let graph_opts = {
    height: 30,
    width: 65,
    barSize: 5,
    barGap: 0,
  };

  if (size === "xl") {
    graph_opts.height = 50;
    graph_opts.width = 75;
    graph_opts.barSize = 7;
  }

  // in the case a graph opts were set
  if (graphOpts) {
    graph_opts = graphOpts;
  }

  const [toooltipId] = useState(_uniqueId("tooltip-"));

  const renderTrend = (change, direction, comparison) => (
    <div className="flex-row d-flex">
      <span
        className={
          "metric__trend metric__trend--" +
          direction +
          clsx(" ", { "is-positive": isPositive === false })
        }
      >
        {direction === "up" ? (
          <Fragment>&#8593;</Fragment>
        ) : (
          <Fragment>&#8595;</Fragment>
        )}
      </span>
      <span
        className={clsx("metric__secondary", {
          "text-green": direction === "up",
          "text-red": direction === "down",
          "is-positive": isPositive === false,
        })}
      >
        {selectFormat("percentage", change)}
      </span>
      {comparison && <span className="metric__comparison">{comparison}</span>}
    </div>
  );

  const renderBarChart = (graph, graph_opts) => (
    <BarChart
      height={graph_opts.height}
      width={graph_opts.width}
      barGap={graph_opts.barGap}
      data={graph}
      style={graph_opts.style}
    >
      <Bar
        type="linear"
        dataKey="value"
        fill="#B2C2C9"
        dot={false}
        barSize={graph_opts.barSize}
        isAnimationActive={false}
      />
      <Tooltip
        content={<CustomMetricToolTip type={type} />}
        cursor={{ fill: "#eee", opacity: 0.5 }}
      />
    </BarChart>
  );

  const renderPrimaryMetric = (type, value, format, showPureValue = false) => {
    let tooltipMetricFormat = "0,0";

    if (type === "currency" || type === "currency-GB") {
      tooltipMetricFormat = format
        ? format
        : (type === "currency-GB" ? "£" : "$") + "0,0.00";
    } else if (type === "percentage" || format?.includes("%")) {
      tooltipMetricFormat = "0.00%";
    } else if (type === "other") {
      tooltipMetricFormat = format;
    }

    return (
      <>
        <span
          data-tooltip-id={toooltipId}
          data-tooltip-html={selectFormat(type, value, tooltipMetricFormat)}
        >
          {!showPureValue
            ? !hidePrimaryValue && selectFormat(type, value, format)
            : value}
        </span>
        <ReactTooltip
          id={toooltipId}
          className="metric__tooltip-full-value metric__tooltip-content"
          place="bottom"
          clickable={true}
          delayHide={0}
        />
      </>
    );
  };

  return showGraphOnly ? (
    <div>
      {change && renderTrend(change, direction)}
      {graph && renderBarChart(graph, graph_opts)}
    </div>
  ) : (
    <div className={`metric ${widget_size} ${widget_type}`}>
      {change || graph ? (
        <>
          <span className="metric__primary">
            {renderPrimaryMetric(type, value, format, showPureValue)}
          </span>
          <div className={chartTrendContainerStyle}>
            {change && renderTrend(change, direction, comparison)}
            {graph && renderBarChart(graph, graph_opts)}
          </div>
        </>
      ) : icon ? (
        <span
          className="metric__primary"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div
            style={{
              width: "1rem",
              height: "0",
              marginRight: ".3rem",
              borderBottom: "1px dashed #FC6D52",
            }}
          />
          {selectFormat(type, value, format)}x
        </span>
      ) : (
        <span className="metric__primary">
          {renderPrimaryMetric(type, value, format, showPureValue)}
        </span>
      )}
    </div>
  );
};

/**
 * Renders a simple trend element without rendering the value
 * @param {*} obj
 * @returns
 */
export const renderTrendArrow = (obj) => {
  return (
    <div
      className={`d-inline-block metric-table__trend metric-table__trend--${
        obj.change > 0 ? "up" : "down"
      } ${obj.className || ""}`}
    >
      {obj.change > 0 ? (
        <Fragment>&#8593;</Fragment>
      ) : obj.change < 0 ? (
        <Fragment>&#8595;</Fragment>
      ) : null}
    </div>
  );
};

export const renderTrend = (change) => (
  <div className="flex-row d-flex align-items-center">
    <span
      className={`metric-table__trend metric-table__trend--${
        change > 0 ? "up" : "down"
      }`}
    >
      {change > 0 ? <Fragment>&#8593;</Fragment> : <Fragment>&#8595;</Fragment>}
    </span>
    <span className="metric__secondary">
      {selectFormat("percentage", change)}
    </span>
  </div>
);

export const renderTrendTable = (metric) => (
  <div className="flex-row d-flex">
    <span
      className={`metric-table__trend metric-table__trend--${
        metric.direction === "up" ? "up" : "down"
      }`}
    >
      {metric.direction === "up" ? (
        <Fragment>&#8593;</Fragment>
      ) : (
        <Fragment>&#8595;</Fragment>
      )}
    </span>
    <span
      className={clsx("metric-table__secondary", {
        "text-green": metric.direction === "up",
        "text-red": metric.direction === "down",
      })}
    >
      {selectFormat("percentage", metric.change)}
    </span>
  </div>
);

/**
 * Return a metric to be set inside a column/table
 * @param {*} cell
 * @param {*} row
 * @param {*} rowIndex
 * @param {*} params
 */
export const getMetricForColumn = (cell, row, rowIndex, params) => {
  const props = { ...cell, ...params };
  return <MetricTable {...props} />;
};

export class Trend extends PureComponent {
  render() {
    const {
      type = "percentage",
      label,
      change,
      direction,
      highlight,
      className,
    } = this.props;

    return (
      <div className={"trend " + (className ? className : "")}>
        <div className="d-flex flex-column">
          <div className="flex-row d-flex">
            <span className={"trend-arrow trend-arrow--" + direction}>
              {direction === "up" ? (
                <Fragment>&#8593;</Fragment>
              ) : (
                <Fragment>&#8595;</Fragment>
              )}
            </span>
            <span
              className={clsx("trend__value", {
                "trend__value--danger": highlight && direction === "down",
              })}
            >
              {selectFormat(type, change)}
            </span>
            <span className="trend__label">{label}</span>
          </div>
        </div>
      </div>
    );
  }
}

export const getCountryFlag = (name) => {
  const renderFlagIcon = flags[name];
  return (
    renderFlagIcon && (
      <img
        src={renderFlagIcon.image}
        alt={renderFlagIcon.name}
        title={renderFlagIcon.name}
        style={{ position: "relative", top: -2 }}
      />
    )
  );
};

// Types: numeral, currency, percentage
export class MiniMetric extends PureComponent {
  render() {
    const { value, change, direction, type, format, icon, iconSize, newValue } =
      this.props;

    const widget_type = type ? `mini-metric--${type}` : "";

    if (change) {
      return (
        <div className={`mini-metric d-flex align-items-center ${widget_type}`}>
          <span
            className={"mini-metric__icon" + (iconSize ? "-" + iconSize : "")}
          >
            {getCountryFlag(icon)}
          </span>
          <span className="mini-metric__primary">
            {selectFormat(type, value, format)}
          </span>
          <span className={"mini-metric__trend metric__trend--" + direction}>
            {direction === "up" ? (
              <Fragment>&#8593;</Fragment>
            ) : (
              <Fragment>&#8595;</Fragment>
            )}
          </span>
          <span className="mini-metric__secondary">
            {selectFormat("percentage", change, format)}
          </span>
        </div>
      );
    } else if (newValue) {
      return (
        <div className={`mini-metric ${widget_type}`}>
          <span className="mini-metric__icon">{getCountryFlag(icon)}</span>
          <span className="mini-metric__primary">new</span>
        </div>
      );
    } else {
      return (
        <div className={`mini-metric ${widget_type}`}>
          <span className="mini-metric__icon">{getCountryFlag(icon)}</span>
          <span className="mini-metric__primary">
            {selectFormat(type, value, format)}
          </span>
        </div>
      );
    }
  }
}

const renderCellValue = (type, format, value, newValue) => {
  if (value !== null && !newValue) {
    return selectFormat(type, value, format);
  } else if (value === null) {
    return "N/A";
  } else if (newValue) {
    return selectFormat(type, value, format) + " new";
  }
};

export const MetricTable = (props) => {
  const {
    value,
    newValue,
    change,
    hideChangeValue,
    direction,
    type,
    format,
    graph,
    graphType = "line",
    isPositive,
  } = props;
  const charts = (
    <div className="d-flex">
      {graph && graphType === "line" && (
        <LineChart height={30} width={90} data={graph}>
          <Line type="linear" dataKey="value" stroke="#adb5bd" dot={false} />
        </LineChart>
      )}
      {graph && graphType === "bar" && (
        <BarChart height={35} width={65} barGap={0} data={graph}>
          <Bar
            type="linear"
            dataKey="value"
            fill="#B2C2C9"
            dot={false}
            barSize={8}
          >
            {graph.map((d, i) => (
              <Cell
                cursor="pointer"
                fill={d.selected ? defaultColors[0] : "#B2C2C9"}
                key={`cell-${i}`}
              />
            ))}
          </Bar>
          <Tooltip
            content={<CustomMetricToolTip />}
            cursor={{ fill: "#eee", opacity: 0.5 }}
          />
        </BarChart>
      )}
    </div>
  );

  return (
    <span>
      {change ? (
        <div className="metric-table d-flex align-items-center">
          <div className="flex-row d-flex align-items-center">
            {!hideChangeValue && (
              <span className="metric-table__primary">
                {value !== "no data"
                  ? selectFormat(type, value, format)
                  : "no data"}
              </span>
            )}
            <span
              className={clsx(" metric-table__trend", {
                "metric-table__trend--up": direction === "up",
                "metric-table__trend--down": direction === "down",
                "is-positive": isPositive === false,
              })}
            >
              {direction === "up" ? (
                <Fragment>&#8593;</Fragment>
              ) : (
                <Fragment>&#8595;</Fragment>
              )}
            </span>
            <span
              className={
                "metric-table__secondary" +
                clsx(" ", {
                  "text-red": direction === "down",
                  "text-green": direction === "up",
                  "is-positive": isPositive === false,
                })
              }
            >
              {selectFormat("percentage", change)}
            </span>
          </div>
          {charts}
        </div>
      ) : (
        <div className="metric-table d-flex align-items-center">
          <span className="metric-table__primary">
            {renderCellValue(type, format, value, newValue)}
          </span>
          {charts}
        </div>
      )}
    </span>
  );
};

export class SocialVideosTooltips extends PureComponent {
  toolTip = (payload) => {
    return [
      payload.date && (
        <div key={"date"} className="value">
          <span className="key">Date</span> : {payload.date}
        </div>
      ),
      payload["AC Successes"] > 0 && (
        <div key={"ac_successes"} className="value">
          <span className="key">AC Succeses</span> :{" "}
          {payload["AC Successes"] > 1000
            ? numeral(payload["AC Successes"]).format("0.0a")
            : numeral(payload["AC Successes"]).format("0a")}
        </div>
      ),
      payload.key_acquisition_metric_name && (
        <div key={"key_acquisition_metric_name"} className="value">
          <span className="key">{payload.key_acquisition_metric_name}</span> :{" "}
          {payload.key_acquisition_metric > 1000
            ? numeral(payload.key_acquisition_metric).format("0.0a")
            : numeral(payload.key_acquisition_metric).format("0a")}
        </div>
      ),
      payload.Impressions > 0 && (
        <div key={"impressions"} className="value">
          <span className="key">Impressions</span> :{" "}
          {payload.Impressions > 1000
            ? numeral(payload.Impressions).format("0.0a")
            : numeral(payload.Impressions).format("0a")}
        </div>
      ),
    ];
  };

  render() {
    const { active } = this.props;

    if (active) {
      const { payload } = this.props;
      if (payload && payload.length > 0) {
        return (
          <div className="custom-tooltip">
            {this.toolTip(payload[0].payload)}
          </div>
        );
      }
    }
    return null;
  }
}

export class SocialTooltip extends PureComponent {
  render = () => {
    const tooltip_data = this.props.date;
    return (
      <StandardTooltip
        render={({ data }) => {
          const payload = data(tooltip_data);
          if (payload !== null) {
            const tooltip_data = payload[0].payload;
            return (
              <div className="standard-tooltip">
                {(tooltip_data.time || tooltip_data.name) && (
                  <header className="standard-tooltip__header">
                    <span className="standard-tooltip__key-header">Date:</span>
                    <span className="standard-tooltip__value-header">
                      {tooltip_data.time
                        ? tooltip_data.time
                        : tooltip_data.name}{" "}
                      --
                    </span>
                  </header>
                )}
                <main className="standard-tooltip__main">
                  {tooltip_data["AC Successes"] > 0 && (
                    <div className="standard-tooltip__item">
                      <span className="standard-tooltip__key">
                        {tooltip_data[1] && (
                          <span
                            className="standard-tooltip__icon"
                            style={{ background: tooltip_data[1].stroke }}
                          />
                        )}
                        Current Period
                      </span>
                      <span className="standard-tooltip__value">
                        {selectFormat("number", tooltip_data.today)}
                      </span>
                    </div>
                  )}

                  {tooltip_data.today > 0 && (
                    <div className="standard-tooltip__item">
                      <span className="standard-tooltip__key">
                        {tooltip_data[0] && (
                          <span
                            className="standard-tooltip__icon"
                            style={{ background: "FEE5DE", opacity: 0.2 }}
                          />
                        )}
                        Today
                      </span>
                      <span className="standard-tooltip__value">
                        {tooltip_data.today > 1000
                          ? numeral(tooltip_data.today).format("0.0a")
                          : numeral(tooltip_data.today).format("0a")}
                      </span>
                    </div>
                  )}

                  {tooltip_data.yesterday > 0 && (
                    <div className="standard-tooltip__item">
                      <span className="standard-tooltip__key">
                        {tooltip_data[0] && (
                          <span
                            className="standard-tooltip__icon"
                            style={{ background: "f89481", opacity: 0.2 }}
                          />
                        )}
                        Yesterday
                      </span>
                      <span className="standard-tooltip__value">
                        {tooltip_data.yesterday > 1000
                          ? numeral(tooltip_data.yesterday).format("0.0a")
                          : numeral(tooltip_data.yesterday).format("0a")}
                      </span>
                    </div>
                  )}
                </main>
              </div>
            );
          }
        }}
      />
    );
  };
}

export const renderLegendsForTable = (data) => {
  return data.map((item, key) => {
    let label = item;
    if (item.includes("_")) {
      label = item.replace("_", " ") + "%";
    }
    const icon_styles = { background: MAIN_COLORS[key] };
    const text_styles = { color: MAIN_COLORS[key] };

    return (
      <span key={key} className="mt-2 legend mt-md-0">
        <span className="legend__icon" style={icon_styles} />
        <span className="legend__text" style={text_styles}>
          {label}
        </span>
      </span>
    );
  });
};

/**
 * Returns a list of legends
 */
export const renderLegends = (data) =>
  data.map((entry, i) => (
    <span key={i} className="mt-2 legend mt-md-0">
      <span className="legend__icon" style={{ background: MAIN_COLORS[i] }} />
      <span className="legend__text" style={{ color: MAIN_COLORS[i] }}>
        {entry}
      </span>
    </span>
  ));

export const TableBarChart = (data, isResponsive = false) => {
  let scaleType = "auto";

  if (data.length > 1) {
    let scalePercentage =
      ((data[0].value - data[1].value) / data[0].value) * 100;
    scaleType = scalePercentage > 60 ? "log" : "auto";
  }
  let barChart = (
    <BarChart
      height={70}
      width={210}
      data={data}
      margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
      barCategoryGap={0}
      barGap={0}
    >
      <defs>
        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#FAA461" stopOpacity={1} />
          <stop offset="95%" stopColor="#FC6D52" stopOpacity={1} />
        </linearGradient>
      </defs>
      <Bar dataKey="value">
        {data.map((entry, i) => {
          return <Cell key={i} fill={MAIN_COLORS[i]} />;
        })}
      </Bar>
      <YAxis hide scale={scaleType} domain={[0.01, "auto"]} allowDataOverflow />
      <XAxis
        dataKey="value"
        axisLine={false}
        tickLine={false}
        tick={{ strokeWidth: 0, fill: "#25263F" }}
        tickFormatter={(value) => selectFormat("number", value)}
        interval={0}
        fontSize={11}
        fontWeight={700}
        fontFamily={"Museo Slab W01"}
      />
    </BarChart>
  );

  return isResponsive ? (
    <ResponsiveContainer width="95%" height={70}>
      {barChart}
    </ResponsiveContainer>
  ) : (
    <React.Fragment>{barChart}</React.Fragment>
  );
};

export class TableTitleBarChart extends PureComponent {
  render() {
    const { title, row, bar_chart_data } = this.props;

    // let NAME = row[Object.keys(row)[0]]
    let NAME = "";

    if (title !== null) {
      NAME = (
        <h3 className="content-table-bar-chart__name">
          {truncate(title, 240)}
        </h3>
      );
    }

    let DESCRIPTION = "";
    if (row.description !== null) {
      DESCRIPTION = (
        <h3 className="content-table-bar-chart__description">
          {truncate(row.description, 240)}
        </h3>
      );
    }

    return (
      <article className="content-table-bar-chart">
        <section className="row">
          <div className="col-6 d-flex align-items-left flex-column justify-content-center">
            {NAME}
            {DESCRIPTION}
          </div>
          <div className="col-6 justify-content-end">
            {TableBarChart(bar_chart_data)}
          </div>
        </section>
      </article>
    );
  }
}

export class TableColumnBarChart extends PureComponent {
  render() {
    const { bar_chart_data, isResponsive } = this.props;

    return (
      <article className="content-table-bar-chart">
        <section className="row">
          <div className="col-12 justify-content-center">
            {TableBarChart(bar_chart_data, isResponsive)}
          </div>
        </section>
      </article>
    );
  }
}

export const Loader = (minHeight, size) => {
  return (
    <div className="col-12">
      <div style={{ minHeight: minHeight }} className="loader">
        <div className={`circle-scale ${size}`} />
      </div>
    </div>
  );
};

export const Error = ({
  msg = "An internal error has prevented this widget from getting data.",
  type = "no_data",
  title,
  page_title,
  minHeight,
  report,
}) => {
  const errors = {
    no_data: {
      msg: "Hmm, there is no data for this widget because of the date range or filters that were chosen.",
      details: "",
    },
    query_feed_insights: {
      msg: "There are no insights.",
      details: "Please select a different time range.",
    },
    endpoint: {
      msg,
      details: "Endpoint error (endpoint returns internal error).",
    },
    no_meta: {
      msg,
      details: "Missing endpoint meta data (dates for datepicker).",
    },
    timeout: { msg, details: "Timeouts (query runs too long and time out)." },
    no_cache: {
      msg,
      details: "Cache Miss (endpoint exists but there is no data in cache).",
    },
    crashed: { msg: "We encountered an error when loading the dashboard." },
    graphql_down: { msg: "Unable to establish API connection" },
  };

  Sentry.captureException(errors[type], {
    extra: {
      message: msg,
    },
  });

  return (
    <div className="col">
      <div
        style={{ minHeight: minHeight }}
        className="error d-flex align-items-center justify-content-center"
      >
        <Heading size={5}>
          <p className="loader-message text-info">{errors[type].msg}</p>
          {errors[type].details ? (
            <p className="loader-message text-info">{errors[type].details}</p>
          ) : null}
        </Heading>
      </div>
    </div>
  );
};

export const Notification = (text) => (
  <div className="col-12">
    <div style={{ minHeight: 360 }} className="notification">
      <Heading size={5}>
        <p className="notification__message">{{ text }}</p>
      </Heading>
    </div>
  </div>
);

/**
 * Get the width of the element according the input text
 * @param {*} text Text to be used as reference to calculate the width
 * @param {*} size You could be setting it to "large" to get a larger font size
 * @param {*} className When setting up a className, it overwrites the default style
 */
export const getTextWidth = (text, size, className = "") => {
  const div_element = document.createElement("div");
  document.body.appendChild(div_element);

  if (className) {
  } else {
    div_element.id =
      size === "large"
        ? "hidden_filter_text_width_large"
        : "hidden_filter_text_width";
    // div_element.style.fontSize = parseFloat(window.getComputedStyle(div_element, null).getPropertyValue('font-size'));
    div_element.style.fontSize = size === "large" ? "23.7px" : "1.2rem";
    div_element.style.fontWeight = 700;
  }
  div_element.style.position = "absolute";
  div_element.style.left = -1000;
  div_element.style.top = -1000;
  div_element.innerHTML = text;

  const width = div_element.clientWidth;
  div_element.remove();
  return width;
};

const palette = new ColorPalette({
  upperBoundS: 90,
  lowerBoundS: 20,
  upperBoundL: 70,
  lowerBoundL: 50,
});

// random sequence
const colorSequence = palette
  .generateSequence(20, true)
  .map((hsl) => hsl.toHEX().toString());
export const MAIN_COLORS = defaultColors.concat(colorSequence);

export const FUNNEL_GRADIENTS = [
  ["#A1CFEA", "#A2E9F2"],
  ["#A1DFC3", "#D8F2E7"],
  ["#FBA2D3", "#FCDBED"],
  ["#FEEDB1", "#FFF8E0"],
  ["#14874d", "#38D690"],
  ["#F3108E", "#EC8FC3"],
  ["#F7A4D2", "#F9D5E9"],
  ["#24DE85", "#A0E2C2"],
  ["#19df85", "#AEF0D2"],
  ["#F69484", "#F2C6BF"],
  ["#5ECA6D", "#C3E2C8"],
  ["#2FC2B0", "#88C5BE"],
  ["#f7de8a", "#fcf2d2"],
  ["#22cd7b", "#58e3a1"],
  ["#e35c58", "#ee9c9a"],
  ["#77baf0", "#a5d1f5"],
  ["#2AC8DC", "#D2F4F8"],
];

export const PIE_COLORS = MAIN_COLORS;

export const BAR_CHART_SCHEME = [
  "#55A1B2",
  "#A6D940",
  "#D7185B",
  "#78CEC0",
  "#F9EA4F",
  "#259E78",
  "#C8A73B",
  "#C75845",
  "#C01771",
  "#F92060",
  "#2FC2B0",
  "#FCA43E",
  "#198753",
  "#1E848A",
  "#DE2B2F",
  "#F55290",
  "#5ECA6D",
  "#27A3BA",
  "#FBD14B",
  "#FA6E57",
  "#FC2C45",
  "#F01F8E",
  "#1F85C6",
  "#21AA68",
  "#F7DE8A",
  "#FA6E57",
  "#F5A5D1",
  "#FA6E57",
  "#F5A5D1",
  "#F69484",
  "#2AC8DC",
  "#2CDC88",
];

// Dials
export const INSTAGRAM_COLORS = ["#8a3ab9", "#f4f4f4"];
export const VIMEO_COLORS = ["#45bbff", "#f4f4f4"];
export const YOUTUBE_COLORS = ["#CD201F", "#f4f4f4"];
export const FACEBOOK_COLORS = ["#3b5998", "#f4f4f4"];
export const TWITTER_COLORS = ["#16C8DE", "#f4f4f4"];

// Socials
export const SOCIALS_COLORS = {
  facebook: ["#306EB5", "#f2f2f2"],
  twitter: ["#50B9D2", "#f2f2f2"],
  youtube: ["#E52D27", "#f2f2f2"],
  tiktok: ["#FFDC80", "#f2f2f2"],
  instagram: ["#E1306C", "#f2f2f2"],
  pinterest: ["#BD081C", "#f2f2f2"],
  linkedin: ["#0077B5", "#f2f2f2"],
  reddit: ["#FF4500", "#f2f2f2"],
  twitch: ["#6441A5", "#f2f2f2"],
  vimeo: ["#1AB7EA", "#f2f2f2"],
  whatsapp: ["#25D366", "#f2f2f2"],
  snapchat: ["#FFFC00", "#f2f2f2"],
  telegram: ["#0088CC", "#f2f2f2"],
  discord: ["#7289DA", "#f2f2f2"],
  tumblr: ["#35465C", "#f2f2f2"],
  quora: ["#B92B27", "#f2f2f2"],
  "instagram story impressions": ["#8A37B8", "#5c37b8"],
};

// Custom Color Index
export const customColorIndex = (i, colorIndex) =>
  colorIndex ? colorIndex * i : i;

// Icons
export const renderIcons = (icons, icons_size) => {
  const iconClasses = clsx("icon", { [`icon--${icons_size}`]: true });

  return icons.map((item, key) => (
    <img
      key={key}
      className={iconClasses}
      src={selectIcon(item)}
      alt="{item}"
    />
  ));
};

export const selectIcon = (name) => {
  switch (name) {
    case "youtube":
      return youtube_icon;
    case "facebook":
      return facebook_icon;
    case "twitter":
      return twitter_icon;
    case "instagram":
      return instagram_icon;
    case "snapchat":
      return snapchat_icon;
    case "parsely":
      return parsely_icon;
    case "bigquery":
      return bigquery_icon;
    case "snowflake":
      return snowflake_icon;
    case "tiktok":
      return tiktok_icon;
    case "A_score":
      return A_score;
    case "B_score":
      return B_score;
    case "C_score":
      return C_score;
    default:
      return null;
  }
};

export const getIcon = (name, size) => {
  switch (name) {
    case "youtube":
      return <FaYoutube size={size} />;
    case "facebook":
      return <FaFacebookSquare size={size} />;
    case "twitter":
      return <FaTwitterSquare size={size} />;
    case "instagram":
      return <FaInstagram size={size} />;
    case "snapchat":
      return <FaSnapchat size={size} />;
    case "tiktok":
      return <FaTiktok size={size} />;
    default:
      return null;
  }
};

export const DatePickerMobile = (props) => (
  <Responsive {...props} maxWidth={768} />
);
export const DatePickerTablet = (props) => (
  <Responsive {...props} minWidth={768} maxWidth={1184} />
);
export const DatePickerDesktop = (props) => (
  <Responsive {...props} minWidth={1185} />
);

/**
 * Component that shows a message informing which widget isn't declared yet in the `components.js`. It avoids an exception
 * that is thrown everytime that a widget isn't declared and the return of the `components.js` is an object rather than a
 * function (React class)
 * @param {*} props
 */
export const MissingWidget = (props) => (
  <h5>Missing the widget {props.widget || ""}</h5>
);

export const getCurrentTenant = (project) => {
  let tenant = "";
  let project_name = project;
  const path = window.location.pathname || "/";

  if (path !== "/" && path !== "/login" && path.split("/")[1] !== "") {
    project_name = path.split("/")[1];
  }

  switch (project_name) {
    case "intercept":
    case "fov":
    case "topic":
      tenant = "firstlookmedia";
      break;

    case "sky-news":
    case "sky-sports":
    case "sky-rollup":
    case "sky-football-daily":
      tenant = "sky";
      break;

    case "lee-enterprises":
    case "husker-extra":
    case "lincoln-journal-star":
    case "omaha-world-herald":
    case "rapid-city-journal":
      tenant = "lee-enterprises";
      break;

    case "lee-rollup":
    case "lee-revenue":
      tenant = "lee";
      break;

    case "demo":
      tenant = "your-biz";
      break;

    default:
      tenant = project;
  }

  return tenant;
};

export const getDashboardVersion = () => {
  const url = window.location.search;
  const version = new Uri(url).getQueryParamValue("version");
  return version ? version : "production";
};

export const getImageSrc = (url) =>
  url ? url.replace(/'/g, "%27") : missing_image;

/**
 * Fuzzy search without needing extra libs
 * @param {*} searchString the pattern to search
 * @param {*} searchSource the source for the search
 * @returns
 */
export const fuzzySearch = (searchString = "", searchSource) => {
  if (searchString.length === 0) return true;
  let buf = ".*" + searchString.replace(/(.)/g, "$1.*").toLowerCase();
  return new RegExp(buf).test(searchSource.toLowerCase());
};

/*
 * Look for the element that contains more keys, which will be used as reference for the whole data
 * @param {*} data Data source to look for all keys
 * @param {*} ignoredKeys Keys to be ignored
 * @returns
 */
export const getAllKeys = (data, ignoredKeys) =>
  Object.keys(
    data.reduce((accum, current) => {
      Object.keys(current).forEach((key) => (accum[key] = current[key]));
      return accum;
    }, {})
  ).filter((key) => !(ignoredKeys || []).includes(key));

/**
 * Checks whether arrays as equal or not
 * @param {*} a
 * @param {*} b
 * @returns
 */
export const areArraysDifferent = (a, b) =>
  a.length !== b.length || differenceWith(a, b, isEqual).length > 0;

/**
 * Calculate graph phases widths to be displayed in the background of a chart
 * @param {*} data
 * @param {*} phases
 * @returns
 */
export const getPhaseWidths = (data, phases) => {
  const phaseWidthGroupped = [];
  const wSize = 100 / data.length;
  phases.forEach((phase, id) => (phase.id = id));
  data.forEach((d) => {
    const currentPhase = phases.find((phase) => {
      return (
        new Date(d.date).getTime() >= new Date(phase.start).getTime() &&
        new Date(d.date).getTime() <= new Date(phase.end).getTime()
      );
    });

    // get the last group
    const lastGroup = phaseWidthGroupped[phaseWidthGroupped.length - 1];

    if (!lastGroup || lastGroup.name !== currentPhase?.name) {
      phaseWidthGroupped.push({
        name: currentPhase?.name,
        width: wSize,
      });
    } else {
      lastGroup.width = lastGroup.width + wSize;
    }
  });

  return phaseWidthGroupped.map((w) => ({
    ...w,
    width: w.width + "%",
  }));
};

const getPhaseStyles = (el, i) => ({
  width: el.width,
  backgroundColor: i % 2 === 0 ? "#FEF0EE" : "",
});

export const renderPhases = (phaseWidths) => (
  <div className="composed-chart__phase_bg">
    {phaseWidths.map((el, i) => (
      <div
        key={i}
        className="composed-chart__phase_bg--item"
        style={getPhaseStyles(el, i)}
      />
    ))}
  </div>
);

export const renderPhaseTitles = (phaseWidths) => {
  return (
    <div className="d-flex phase-titles-bg">
      {phaseWidths.map((el, i) => (
        <div
          key={i}
          style={{ width: el.width }}
          className={clsx("phase-titles-bg__item", { "no-boder": !el.name })}
        >
          <span>{el.name}</span>
        </div>
      ))}
    </div>
  );
};

/**
 * Sort the legends by specifying what legends should be displayed at begining
 * @param {*} dataKeysSorted
 * @param {*} sequentialKeys
 * @returns
 */
export const getSpecifiedLegendOrder = (dataKeysSorted, sequentialKeys) => {
  // defines the elements that should be displayed first
  const sequentialLegends = sequentialKeys;
  const sortedKeys = dataKeysSorted;

  const newOrder = [
    // get all elements that should be displayed only in the case they really exist in the data keys
    ...sequentialLegends.filter((key) => sortedKeys.includes(key)),
    // remove all keys that are already coming from the sequentialLegends
    ...sortedKeys.filter((key) => !sequentialLegends.includes(key)),
  ];

  return newOrder;
};
