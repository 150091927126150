import React from 'react';

const ErrorComponent = ({ message }) => (
  <div
    className="relative max-w-md px-4 py-2 py-3 mx-auto text-sm text-red-700 bg-red-100 border border-red-400 rounded"
    role="alert"
  >
    <strong className="font-bold">Error! </strong>
    <span className="block sm:inline">{message}</span>
  </div>
);

export { ErrorComponent };
