import axios from 'axios';
import * as echarts from 'echarts';
import { createRoot } from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import pickaxe from '../src/styles/echarts-pickaxe-theme.js';
import '../src/styles/index.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import NavMain from './components/Header/NavMain';
import Loader from '../src/components/Loader';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import App from './pages/App';
import Login from './pages/Login';
import SSO from './pages/Login/SSO';
import NoData from '@widget-builder/src/components/ui/NoData';
import FallbackPage from './pages/FallbackPage';

const WidgetBuilder = lazy(() => import('@widget-builder').then(module => ({ default: module.WidgetBuilder })));
const Dashboard = lazy(() => import('@dashboard/pages/Dashboard'));
const Settings = lazy(() => import('@dashboard/pages/Settings'));
const MixBuilder = lazy(() => import('@mix').then(module => ({ default: module.MixBuilder })));
const QueryBuilder = lazy(() => import('@query-builder').then(module => ({ default: module.QueryBuilder })));
const DataSource = lazy(() => import('@data-sources').then(module => ({ default: module.DataSource })));

import { WidgetBuilderSkeleton } from '@dashboard/utils/WidgetBuilderSkeleton';

import NoMatch from './shared/NoMatch';

if (process.env.NODE_ENV === 'production') {
  window.addEventListener('error', function (event) {
    console.log('Original Error:', event.error);
    console.log('Error Stack:', event.error?.stack);
    console.log('Component Stack:', event.error?.componentStack);
  });
}

// Axios interceptor to add authorization headers
axios.interceptors.request.use(request => {
  const token = window.localStorage.getItem('token') || window.localStorage.getItem('token');
  request.headers.Authorization = token ? `Bearer ${token}` : '';
  request.headers.ContentType = 'application/json';
  return request;
});

// Register the ECharts theme
echarts.registerTheme('pickaxe', pickaxe);

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: 'fallback',
        element: <FallbackPage />,
      },
      { index: true, element: <Dashboard /> },
      { path: ':project/:board?', element: <Dashboard /> },
      {
        path: 'query',
        element: (
          <Suspense fallback={<Loader />}>
            <QueryBuilder />
          </Suspense>
        ),
      },
      {
        path: 'mix',
        element: (
          <Suspense fallback={<Loader />}>
            <MixBuilder />
          </Suspense>
        ),
      },
      {
        path: 'settings/*',
        element: (
          <Suspense fallback={<Loader />}>
            <Settings />
          </Suspense>
        ),
      },
      { path: '*', element: <NoMatch /> },
    ],
  },
  {
    path: '/builder/:projectSlug?/:widgetId?/:boardSlug?',
    element: (
      <Suspense fallback={<WidgetBuilderSkeleton />}>
        <WidgetBuilder />
      </Suspense>
    ),
  },
  {
    path: '/login',
    element: (
      <Suspense fallback={<Loader />}>
        <Login />
      </Suspense>
    ),
  },
  {
    path: '/sso',
    element: (
      <Suspense fallback={<Loader />}>
        <SSO />
      </Suspense>
    ),
  },

  {
    path: '/settings/data-sources/*',
    element: (
      <Suspense fallback={<Loader />}>
        <DataSource />
      </Suspense>
    ),
  },
  {
    path: '/no-data',
    element: (
      <Suspense fallback={<Loader />}>
        <NavMain />
        <NoData />
      </Suspense>
    ),
  },
]);

const container = document.getElementById('root');
const root = createRoot(container);

const queryClient = new QueryClient();

// Render the application
root.render(
  <Auth0Provider
    domain={import.meta.env.VITE_APP_OAUTH_DOMAIN}
    clientId={import.meta.env.VITE_APP_OAUTH_CLIENT_ID}
    redirectUri={`${window.location.origin}/sso`}
  >
    <QueryClientProvider client={queryClient} contextSharing={true}>
      <RouterProvider router={router} />
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </QueryClientProvider>
  </Auth0Provider>
);
