export const WIDGET_QUERIES = `
  query runQueries($projectID: ID!, $tenantID: ID!, $queries: [QueryInput]) {
    runQueries(projectID: $projectID, tenantID: $tenantID, queries: $queries) {
      id
      result
    }
  }
`;

export const WIDGET_LOAD_QUERY = `
  query loadQuery($tenantID: ID!, $queryID: String!) {
    loadQuery(tenantID: $tenantID, queryID: $queryID) {
      query
      originalQuery
    }
  }
`;

export const WIDGET_CREATE = `
  mutation widgetCreate($boardConfigID: ID!, $tenantID: ID!, $input: WidgetV2Input!) {
    widgetV2Create(boardConfigID: $boardConfigID, tenantID: $tenantID, input: $input) {
      id
    }
  }
`;

export const WIDGET_READ = `
  query widgetV2($tenantID: ID!, $id: ID) {
    widgetV2(tenantID: $tenantID, id: $id) {
      id
      name
      type
      component
      className
      icon
      position
      props {
        widgetTitle
        widgetSize
        options
        styles
        
      }
      children {
        id
        name
        type
        component
        icon
        className
        props {
          queries {
            id
            query 
            responseType
          }
          styles
          options
        }
        children {
          id
          name
          type
          component
          icon
          className
          props {
            queries {
              id
              query
              responseType
            }
            styles
            options
          }
          children {
            id
            name
            type
            component
            icon
            className
            props {
              queries {
                id
                query
                responseType
              }
              styles
              options
            }
          }
        }
      }
      createdAt
    }
  }
`;

export const WIDGET_UPDATE = `
  mutation widgetUpdate($widgetID: ID!, $tenantID: ID!, $input: WidgetV2Input!) {
    widgetV2Update(widgetID: $widgetID, tenantID: $tenantID, input: $input) {
    id
      props {
        widgetTitle
      }
    }
  }
`;

export const WIDGET_DELETE = `
  mutation widgetDelete($widgetID: ID!, $tenantID: ID!) {
    widgetV2Delete(widgetID: $widgetID, tenantID: $tenantID)
  }
`;

export const WIDGET_MOVE_POSITION = `
  mutation widgetMovePosition($tenantID: ID!, $widgetID: ID!, $position: Int!) {
    widgetV2PositionUpdate(tenantID: $tenantID, widgetID: $widgetID, position: $position) {
      position
    }
  }
`;

export const WIDGET_TEMPLATE_CREATE = `
  mutation widgetTemplateCreate($input: WidgetV2Input!) {
    widgetTemplateCreate(input: $input)
  }
`;

export const WIDGET_TEMPLATE_UPDATE = `
  mutation widgetTemplateUpdate($id: ID!, $input: WidgetV2Input!) {
    widgetTemplateUpdate(id: $id, input: $input)
  }
`;

export const WIDGET_TEMPLATE_DELETE = `
  mutation widgetTemplateDelete($id: ID!) {
    widgetTemplateDelete(id: $id)
  }
`;

export const COPY_BOARD_CONFIG = `
  mutation widgetV2Copy($tenantID: ID!, $sourceBoardConfigId: ID!, $targetBoardConfigId: ID!) {
    widgetV2Copy(tenantID: $tenantID, sourceBoardConfigId: $sourceBoardConfigId, targetBoardConfigId: $targetBoardConfigId )
  }
`;

export const LOAD_QUERY = `
  query loadQuery($tenantID: ID!, $queryID: String!) {
    loadQuery(tenantID: $tenantID, queryID: $queryID)
  }
`;
