import axiosInstance from './axios';

import { WIDGET_QUERIES, WIDGET_CREATE, WIDGET_READ, WIDGET_UPDATE, WIDGET_DELETE } from './../graphql';
import { WIDGET_MOVE_POSITION } from '../graphql/widgetBuilderGraphql';

const GRAPHQL_URI = import.meta.env.VITE_APP_GRAPHQL_URI;

export const getWidgetData = async queries => {
  try {
    const response = await axiosInstance.post(GRAPHQL_URI, {
      query: WIDGET_QUERIES,
      variables: {
        tenantID: +window.localStorage.getItem('tenantID'),
        projectID: +window.localStorage.getItem('projectID'),
        queries: queries,
      },
    });

    if (response.status !== 200) {
      throw new Error(`HTTP Error: Status code ${response.status}`);
    }

    if (response.data.errors) {
      const message = response.data.errors.map(err => err.message).join(', ');

      throw new Error(`GraphQL Error: ${message}`);
    }

    return response.data.data;
  } catch (error) {
    return error.message;
  }
};

export const widgetCreate = async ({ ...variables }) => {
  const response = await axiosInstance.post(GRAPHQL_URI, {
    query: WIDGET_CREATE,
    variables: {
      tenantID: +window.sessionStorage.getItem('defaultTenantID'),
      ...variables,
    },
  });

  if (response.status !== 200) {
    throw new Error('Error');
  }

  return response.data.data;
};

export const widgetRead = async ({ ...variables }) => {
  const response = await axiosInstance.post(GRAPHQL_URI, {
    query: WIDGET_READ,
    variables: {
      tenantID: +window.sessionStorage.getItem('defaultTenantID'),
      ...variables,
    },
  });

  if (response.status !== 200) {
    throw new Error('Error');
  }

  return response.data.data;
};

export const widgetUpdate = async ({ ...variables }) => {
  const response = await axiosInstance.post(GRAPHQL_URI, {
    query: WIDGET_UPDATE,
    variables: {
      tenantID: +window.sessionStorage.getItem('defaultTenantID'),
      ...variables,
    },
  });

  if (response.status !== 200) {
    throw new Error('Error');
  }

  return response.data.data;
};

export const widgetDelete = async ({ ...variables }) => {
  const response = await axiosInstance.post(GRAPHQL_URI, {
    query: WIDGET_DELETE,
    variables: {
      tenantID: +window.sessionStorage.getItem('defaultTenantID'),
      ...variables,
    },
  });

  if (response.status !== 200) {
    throw new Error('Error');
  }

  return response.data.data;
};

export const widgetMovePosition = async ({ ...variables }) => {
  const response = await axiosInstance.post(GRAPHQL_URI, {
    query: WIDGET_MOVE_POSITION,
    variables: {
      tenantID: +window.sessionStorage.getItem('defaultTenantID'),
      ...variables,
    },
  });

  if (response.status !== 200) {
    throw new Error('Error');
  }

  return response.data.data;
};
