import { useNavigate } from 'react-router-dom';
import useGlobalStore from '/src/stores/globalStore';

const ButtonEditWidget = props => {
  const { projectID, boardID, boardConfigID } = props;
  const setBoardEditMode = useGlobalStore(state => state.setBoardEditMode);
  const navigate = useNavigate();

  const handleClick = () => {
    setBoardEditMode(false);
    navigate(`/editor/${projectID}/${boardID}/${boardConfigID}/new`);
  };

  return (
    <button className="p-2 btn" onClick={() => handleClick()}>
      <i className="fas fa-plus"></i> Add Widget
    </button>
  );
};

export default ButtonEditWidget;
