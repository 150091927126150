import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_APP_GRAPHQL_URI,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default axiosInstance;

axiosInstance.interceptors.request.use(
  config => {
    const token = window.localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
      // config.headers["Cache-Control"] = "no-cache";
      config.headers['Content-Type'] = 'application/json';
    }
    return config;
  },
  error => {
    console.log("Couldn't get token");
    return Promise.reject(error);
  }
);

// axiosInstance.interceptors.response.use(
//   (response) => {
//     return response;
//   },
//   (error) => {
//     const requestUrl = error.config.url;
//     const errorStatus = error.response
//       ? error.response.status.toString()
//       : "DEFAULT";

//     let fallbackData = SAMPLE_DATA_MAP["DEFAULT"];

//     if (error.response) {
//       switch (error.response.status) {
//         case 400:
//           // Handle 400 errors specifically, if needed
//           fallbackData = SAMPLE_DATA_MAP["DEFAULT"]; // Or a specific fallback for 400 errors
//           break;
//         case 401:
//           // Specific handling for 401 errors
//           fallbackData = SAMPLE_DATA_MAP["DEFAULT"];
//           break;
//         default:
//           // Default fallback data
//           fallbackData = SAMPLE_DATA_MAP["DEFAULT"];
//           break;
//       }
//     }

//     // Return the selected fallback data as a resolved promise
//     return Promise.resolve({
//       data: fallbackData,
//       status: error.response ? error.response.status : 500,
//       statusText: fallbackData.statusText,
//     });
//   }
// );
