import ReactEcharts from 'echarts-for-react';
import * as echarts from 'echarts';
import { useMemo } from 'react';

function LoginChart() {
  const option = useMemo(() => {
    // Generate data
    let category = [];
    let dottedBase = +new Date();
    let lineData = [];
    let barData = [];

    for (let i = 0; i < 20; i++) {
      let date = new Date((dottedBase += 3600 * 24 * 1000));
      category.push([date.getFullYear(), date.getMonth() + 1, date.getDate()].join('-'));
      let b = Math.random() * 200;
      let d = Math.random() * 200;
      barData.push(b);
      lineData.push(d + b);
    }

    return {
      backgroundColor: 'transparent',
      tooltip: {
        show: false,
      },
      grid: {
        top: '0%',
        right: '0%',
        bottom: '0%',
        left: '0%',
        containLabel: false,
      },
      xAxis: {
        show: false,
        data: category,
      },
      yAxis: {
        show: false,
        splitLine: { show: false },
      },
      series: [
        {
          name: 'line',
          type: 'line',
          smooth: true,
          showAllSymbol: true,
          symbol: 'emptyCircle',
          symbolSize: 2,
          lineStyle: {
            width: 1,
            color: '#FFFFFF',
            opacity: 0.3,
          },
          itemStyle: {
            color: '#FFFFFF',
            borderWidth: 1,
          },
          data: lineData,
          z: 10,
        },
        {
          name: 'bar',
          type: 'bar',
          barWidth: 20,
          itemStyle: {
            borderRadius: 6,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: '#FC6D52' },
              { offset: 1, color: '#F89481' },
            ]),
          },
          data: barData,
          z: 8,
        },
        {
          name: 'fill',
          type: 'bar',
          barGap: '-100%',
          barWidth: 12,
          itemStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: 'rgba(255,57,68,0.8)' },
              { offset: 0.2, color: 'rgba(255,57,68,0.5)' },
              { offset: 1, color: 'rgba(255,57,68,0.2)' },
            ]),
          },
          z: 6,
          data: lineData,
        },
        {
          name: 'dotted',
          type: 'pictorialBar',
          symbol: 'rect',
          itemStyle: {
            color: '#FF3944',
          },
          symbolRepeat: true,
          symbolSize: [12, 4],
          symbolMargin: 1,
          z: 4,
          data: lineData,
        },
      ],
    };
  }, []); // Empty dependency array ensures new data on each mount

  return (
    <div className="absolute inset-0 w-full h-full overflow-hidden opacity-40">
      <ReactEcharts option={option} style={{ height: '100%' }} opts={{ renderer: 'canvas' }} />
    </div>
  );
}

export default LoginChart;
