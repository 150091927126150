import { useEffect, useState } from "react";
import { redirect } from "react-router-dom";
import keyboardjs from "keyboardjs";

import Modal from "/src/components/Modal";

const AdminTools = () => {
  const tenants = JSON.parse(window.localStorage.getItem("tenants"));

  const [openModal, setOpenModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const toggleAdminTools = () => setOpenModal((prevState) => !prevState);

  const [toggleVersion, setToggleVersion] = useState(false);
  const toggleDashboardVersion = () =>
    setToggleVersion((prevState) => !prevState);

  useEffect(() => {
    keyboardjs.bind("ctrl+t", toggleAdminTools);
    keyboardjs.bind("esc", () => setOpenModal(false));
    keyboardjs.bind("ctrl+shift+d", toggleDashboardVersion);

    return () => {
      keyboardjs.unbind("ctrl+t", toggleAdminTools);
      keyboardjs.bind("esc", () => setOpenModal(false));
      keyboardjs.bind("ctrl+shift+d", () => toggleDashboardVersion);
    };
  });

  const selectTenant = (tenant) => {
    redirect("/");

    window.localStorage.setItem("defaultTenantID", tenant.id);
    window.localStorage.setItem("defaultTenantSlug", tenant.slug);
  };

  return (
    <>
      <div className="absolute top-0 left-0 flex justify-center w-full">
        {toggleVersion && (
          <div className="flex items-center h-8 p-2 text-xs text-white bg-red-600 w-60">
            Version: Development
            <button className="p-1 ml-auto text-white bg-red-900 rounded hover:bg-black hover:text-white">
              Production <i className="fas fa-sync-alt"></i>
            </button>
          </div>
        )}
      </div>
      <Modal
        height="h-full"
        width="w-1/2"
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
      >
        <div className="px-5 mb-2">
          <input
            type="text"
            autoFocus
            className="w-full px-0 mt-4 text-xl border-t-0 border-b border-l-0 border-r-0 border-gray-200 focus:border-gray-200 focus:ring-0"
            placeholder={`Search ${tenants?.length} Tenants`}
            onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
          />
        </div>
        <div className="grid grid-cols-2 gap-0 overflow-y-scroll place-content-stretch justify-items-stretch">
          {tenants?.filter((tenant) =>
              tenant.name.toLowerCase().includes(searchTerm?.toLowerCase())
            )
            .map((tenant, i) => (
              <div
                key={tenant.id}
                className="relative p-2 mx-4 mt-1 text-xs text-left bg-gray-100 rounded cursor-pointer hover:bg-gray-500 hover:text-white"
                onClick={() => selectTenant(tenant)}
              >
                {tenant.name}{" "}
                <div className="absolute top-0 right-0 inline w-16 p-2 text-right text-gray-500 bg-gray-300 rounded-r">
                  ID: {tenant.id}
                </div>
              </div>
            ))}
        </div>
      </Modal>
    </>
  );
};

export default AdminTools;
