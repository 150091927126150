import { useLocation, Link } from "react-router-dom";
import { withRouter } from "../../utils/routerUtils";

const NoMatch = (props) => {
  const { pathname, location } = useLocation();
  const { message, referrer } = props.location.state;

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="flex flex-col items-center w-full mt-5 mb-16 text-center">
        No match for <code>{referrer || pathname || location}</code>
        <span className="text-red-500">{message}</span>
        <Link to="/" className="mt-10 btn">
          Return To Home Page
        </Link>
      </div>
    </div>
  );
};

export default withRouter(NoMatch);
