export const QUERY_DASHBOARD_CONFIG = `
  query projects($tenantID: ID!, $version: String) {
    projects(tenantID: $tenantID) {
      id
      title
      slug
      logo
      param
      nav {
        name
        props {
          insights_url
          queryBuilder
          mix
        }
      }
      insightsConfig {
        widgets {
          url
        }
        feed {
          url
        }
        queryBuilder {
          url
        }
      }
      groups {
        id
        name
        slug
        active
        filters {
          id
          type
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      namedQueries {
        id
        query
        name
        private
        author {
          firstName
          lastName
          email
        }
      }
      boardsV2 {
        id
        title
        slug
        isLive
        position
        configs (version: $version) {
          id
          version
          name
          slug
          isDraft
          widgets {
            id
            name
            type
            position
            component
            className
            icon
            props {
              widgetTitle
              widgetSize
              options
              styles
              queries {
                id
                query
                responseType
              }
            }
            children {
              id
              name
              type
              component
              className
              icon
              props {
                options
                styles                
                queries {
                  id
                  query
                  responseType
                }
              }
              children {
                id
                name
                type
                component
                icon
                className
                props {
                  options
                  styles                
                  queries 
                  {
                    id
                    query
                    responseType
                  }
                }
                children {
                  id
                  name
                  type
                  component
                  icon
                  className
                  props {
                    options
                    styles                
                    queries 
                    {
                      id
                      query
                      responseType
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const QUERY_TENANTS = `
  query {
    tenants {
      id
      name
      slug
    }
  }
`;

export const QUERY_PROJECTS_BOARDS = `
query projects($tenantID: ID!) {
    projects(tenantID: $tenantID) {
      id
      logo
      slug
      title 
      boardsV2 {
        id
        title
        slug
        isLive
        position
      }
    }
  }
`;
