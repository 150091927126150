import React from 'react';
import { Button } from './button';
import { splitCamelCaseToString } from './../../lib/utils';
import { Trash2, Grip } from 'lucide-react';
import useWidgetBuilderStore from '../../stores/useWidgetBuilderStore';
import { useUpdateWidget } from '../../api/useWidgetQueries';

const EditTools = React.memo(() => {
  const { selectedElementId, currentWidgetConfig, currentElementConfig, setCurrentWidgetConfig } =
    useWidgetBuilderStore(state => state);

  if (!currentWidgetConfig || !currentElementConfig) return null;

  const componentName = currentElementConfig?.component 
    ? splitCamelCaseToString(currentElementConfig.component)
    : '';

  const updateWidgetMutation = useUpdateWidget();

  const deleteElementById = (config, idToRemove) => {
    if (!config) return null;

    if (config.children && Array.isArray(config.children)) {
      // Recursively process each child and filter out the one to delete
      config.children = config.children
        .map(child => deleteElementById(child, idToRemove))
        .filter(child => child !== null);
    }

    if (config.id === idToRemove) {
      return null;
    }

    return config;
  };
  const handleDelete = id => {
    const updatedConfig = deleteElementById(currentWidgetConfig, id);
    updateWidgetMutation.mutate({
      widgetID: currentWidgetConfig.id,
      input: updatedConfig,
    });

    setCurrentWidgetConfig(updatedConfig);
  };

  return (
    <span
      id="edit-tools"
      className="absolute py-[2px] h-[20px] px-2 flex items-center text-white bg-gray-700 hover:bg-black -top-[21px] -left-[1px] z-50"
    >
      <Button
        variant="destructive"
        className="h-2 p-0 mt-0 mr-1 bg-transparent border-none cursor-pointer hover:bg-transparent hover:text-orange-500"
        onClick={() => handleDelete(selectedElementId)}
      >
        <Trash2 className="w-3 h-3 cursor-pointer" />
      </Button>

      <span className="text-[10px] uppercase cursor-auto">{componentName}</span>

      <Button className="z-50 h-2 p-0 mt-0 ml-1 bg-transparent border-none cursor-move hover:bg-transparent hover:text-orange-500">
        <Grip className="w-3 h-3" />
      </Button>
    </span>
  );
});

export { EditTools };
