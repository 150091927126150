import { Skeleton } from '@widget-builder/src/components/ui';

function MetricSkeleton() {
  return (
    <div className="space-y-4">
      <div className="flex items-end space-x-4">
        <Skeleton className="w-32 h-16" />
        <Skeleton className="w-20 h-8" />
      </div>
      <Skeleton className="w-full h-24" />
    </div>
  );
}

function ChartSkeleton() {
  return (
    <div className="space-y-4">
      <div className="flex justify-between h-48">
        {Array.from({ length: 8 }).map((_, i) => (
          <Skeleton key={i} className="w-12 h-full" />
        ))}
      </div>
      <div className="flex justify-between">
        {Array.from({ length: 8 }).map((_, i) => (
          <Skeleton key={i} className="w-12 h-4" />
        ))}
      </div>
    </div>
  );
}

function TableSkeleton() {
  return (
    <div className="space-y-4">
      <Skeleton className="w-full h-10" />
      {Array.from({ length: 5 }).map((_, i) => (
        <Skeleton key={i} className="w-full h-8" />
      ))}
    </div>
  );
}

export function WidgetBuilderSkeleton({ widgetType = 'metric' }) {
  const WidgetContentSkeleton = () => {
    switch (widgetType) {
      case 'metric':
        return <MetricSkeleton />;
      case 'chart':
        return <ChartSkeleton />;
      case 'table':
        return <TableSkeleton />;
      default:
        return <Skeleton className="w-full h-48" />;
    }
  };

  return (
    <div className="grid w-full h-full grid-cols-6">
      {/* Left sidebar skeleton */}
      <div className="col-span-1 p-4">
        <Skeleton className="w-full h-8 mb-4" />
        <Skeleton className="w-full h-[calc(100vh-120px)]" />
      </div>

      {/* Main content skeleton */}
      <div className="col-span-4 p-5 bg-gray-300">
        <div className="p-10 bg-white">
          {/* Widget header */}
          <div className="flex items-center justify-between mb-6">
            <Skeleton className="h-8 w-[200px]" />
            <Skeleton className="h-8 w-[120px]" />
          </div>

          {/* Widget content */}
          <div className="space-y-4">
            <WidgetContentSkeleton />
          </div>
        </div>
      </div>

      {/* Right sidebar skeleton */}
      <div className="col-span-1 p-4">
        <Skeleton className="w-full h-12 mb-4" />
        <div className="space-y-4">
          <Skeleton className="w-full h-8" />
          <Skeleton className="w-full h-8" />
          <Skeleton className="w-full h-32" />
        </div>
      </div>
    </div>
  );
}
