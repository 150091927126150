// How to use:
//
// import {ColorPalette, HEX, RGB, HSL, defaultColors } from 'ColorPalette';
//
// const colorPalette = new ColorPalette()
//
// // generate array of HSL colors [{h, s, l}]
// const colors = colorPalette.generateSequence(20);
//
// // generate array of HEX strings [string], #fc6d52
// const colors = colorPalette.generateSequence(20).map(hsl => hsl.toHEX().toString());
//
// to add more randomization in selecting colors, use second boolean argument, return [{h, s, l}]
// const randomizedColors = colorPalette.generateSequence(20, true);

export const defaultColors = [
  "#fc6d52",
  "#fcd239",
  "#15c8de",
  "#15de85",
  "#f3108e",
  "#87c544",
  "#b85a5c",
  "#86c499",
  "#c164a5",
  "#757ec9",
  "#c08e4a",
  "#9f50d4",
  "#607e3f",
  "#c9b0bb",
  "#65757d",
];

export class HEX {
  constructor(color) {
    this.color = color;
  }
  toHEX() {
    return this;
  }
  toRGB() {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(this.color);
    return result
      ? new RGB({
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        })
      : null;
  }
  toHSL() {
    return this.toRGB().toHSL();
  }
  toString() {
    return this.color;
  }
}

export class RGB {
  constructor(rgb) {
    if (!rgb) rgb = {};

    const { r, g, b } = rgb;
    this.r = r;
    this.g = g;
    this.b = b;
  }
  toHEX() {
    return new HEX(
      "#" +
        ((1 << 24) + (this.r << 16) + (this.g << 8) + this.b)
          .toString(16)
          .slice(1)
    );
  }
  toRGB() {
    return this;
  }
  toHSL() {
    const r = this.r / 255,
      g = this.g / 255,
      b = this.b / 255;

    let max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h,
      s,
      l = (max + min) / 2;

    if (max === min) {
      h = s = 0; // achromatic
    } else {
      let d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
        default:
      }

      h /= 6;
    }

    return new HSL({
      h: Math.floor(h * 360),
      s: Math.floor(s * 100),
      l: Math.floor(l * 100),
    });
  }
  toString() {
    return `rgb(${this.r}, ${this.g}, ${this.b})`;
  }
}

export class HSL {
  constructor(hsl) {
    if (!hsl) hsl = {};

    const { h = 0, s = 70, l = 60 } = hsl;
    this.h = h;
    this.s = s;
    this.l = l;
  }
  toHEX() {
    return this.toRGB().toHEX();
  }
  toRGB() {
    const h = this.h / 360,
      s = this.s / 100,
      l = this.l / 100;
    let r, g, b;

    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      function hue2rgb(p, q, t) {
        if (t < 0) t += 1;
        if (t > 1) t -= 1;
        if (t < 1 / 6) return p + (q - p) * 6 * t;
        if (t < 1 / 2) return q;
        if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
        return p;
      }

      let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      let p = 2 * l - q;

      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }

    return new RGB({
      r: Math.floor(r * 255),
      g: Math.floor(g * 255),
      b: Math.floor(b * 255),
    });
  }
  toHSL() {
    return this;
  }
  toString() {
    return `hsl(${this.h}, ${this.s}%, ${this.l}%)`;
  }
}

// @baseColors: Array[hex string]
export class ColorPalette {
  constructor(opts) {
    if (!opts) opts = {};

    const {
      hn = 20,
      sn = 10,
      ln = 10,
      // upperBound and lowerBound for saturation and lightness
      upperBoundS = 80,
      lowerBoundS = 20,
      upperBoundL = 70,
      lowerBoundL = 40,
      // delta hue, to select similar colors with close hue value
      deltaH = 45,
      baseColors = defaultColors,
    } = opts;

    this.hn = hn;
    this.sn = sn;
    this.ln = ln;
    this.upperBoundS = upperBoundS;
    this.lowerBoundS = lowerBoundS;
    this.upperBoundL = upperBoundL;
    this.lowerBoundL = lowerBoundL;
    this.deltaH = deltaH;

    // save baseColors in a hex format
    this.baseColors = baseColors.map((d) => new HEX(d));
  }

  // update options
  update(opts) {
    Object.keys(opts).map((opt) => (this[opt] = opts[opt]));
    return this;
  }

  randomHSL({ h, s, l }) {
    return new HSL({
      h: h || Math.floor(Math.random() * 360),
      s: s || Math.floor(Math.random() * 100),
      l: l || Math.floor(Math.random() * 100),
    });
  }

  randomRGB({ r, g, b }) {
    return new RGB({
      r: r || Math.floor(Math.random() * 256),
      g: g || Math.floor(Math.random() * 256),
      b: b || Math.floor(Math.random() * 256),
    });
  }

  // apply hue to hsl color
  h(hsl, h) {
    const { s, l } = hsl;
    return new HSL({ h, s, l });
  }

  // apply saturation to hsl color
  s(hsl, s) {
    const { h, l } = hsl;
    return new HSL({ h, s, l });
  }

  // apply lightness to hsl color
  l(hsl, l) {
    const { h, s } = hsl;
    return new HSL({ h, s, l });
  }

  // n - the number of colors, uniform distributed on interval [0, 360]
  // s - saturated
  // l - lightness
  generatePalette(hn = 10, sn = 3, ln = 3) {
    const palette = {
      h: [], // Array[hsl], s,l - fixed
      hs: [], // Array[Array[hsl]], l - fixed
      hl: [], // Array[Array[hsl]], s - fixed
      hsl: [], // Array[Array[Array[hsl]]]
    };

    // generate HSL colors by hues
    palette.h = this.generateH(hn, new HSL());

    if (sn) {
      palette.hs = palette.h.map((hsl) => this.generateS(sn, hsl));
    }

    if (ln) {
      palette.hl = palette.h.map((hsl) => this.generateL(ln, hsl));
    }

    if (sn && ln) {
      palette.hsl = palette.hs.map((hsls) =>
        hsls.map((hsl) => this.generateL(ln, hsl))
      );
    }

    return palette;
  }

  generateH(n, hsl) {
    const step = Math.floor(360 / n);
    const _colors = [];

    for (let i = 0; i < n; i++) {
      _colors.push(this.h(hsl, step * i + step / 2));
    }

    return _colors;
  }

  generateS(n, hsl) {
    const { upperBoundS, lowerBoundS } = this;
    const step = Math.floor((upperBoundS - lowerBoundS) / n);
    const _colors = [];

    for (let i = 0; i < n; i++) {
      _colors.push(this.s(hsl, lowerBoundS + step * i + step / 2));
    }

    return _colors;
  }

  generateL(n, hsl) {
    const { upperBoundL, lowerBoundL } = this;
    const step = Math.floor((upperBoundL - lowerBoundL) / n);
    const _colors = [];

    for (let i = 0; i < n; i++) {
      _colors.push(this.l(hsl, lowerBoundL + step * i + step / 2));
    }

    return _colors;
  }

  // 1. split hue into N regions and place each color into corresponding cell
  // 2. choose new colors from empty cells
  // 3. for each new color generate [MxM] color palette with different M saturations and M lightness parameters
  // 4. for each new color choose all selected neighbour colors from interval [hue - delta, hue + delta]
  //    and then define saturation and lightness such that this color will become the most unlike
  //
  // n - The number of new colors
  // return Array[HSL] - array of HSL colors [{h, s, l}]
  generateSequence(n, randomize = false) {
    const { sn, ln, deltaH, baseColors } = this;
    const _colors = baseColors.map((d) => d.toHSL());

    for (let k = 0; k < n; k++) {
      if (_colors.length) {
        // choose a new color
        // sort colors by hue in asc order
        const colors = _colors.map((hsl) => hsl.h).sort((a, b) => a - b);
        let max = 0,
          hue = 0;

        // if (randomize && colors.length > 10) {
        //   // randomly select color from 10 colors with the rarest hue values
        //   const dists = colors.map((d, i) => {
        //     if (i == colors.length - 1) {
        //       return {
        //         dist: 360 + colors[0] - d,
        //         hue: d
        //       }
        //     } else {
        //       return {
        //         dist: colors[i + 1] - d,
        //         hue: d
        //       }
        //     }
        //   })
        //   dists.sort((a,b) => b.dist - a.dist);
        //   const idx = randomInt(0, Math.min(10, dists.length));
        //   hue = dists[idx].hue + dists[idx].dist / 2;

        if (randomize) {
          hue = Math.floor(Math.random() * 360);
        } else {
          // select color with the rarest hue value
          colors.push(360 + colors[0]);

          // find sibling points with the largest distance
          for (let i = 0; i < colors.length - 1; i++) {
            const dist = colors[i + 1] - colors[i];
            if (dist > max) {
              max = dist;
              hue = (colors[i + 1] + colors[i]) / 2;
              // hue = parseInt(gaussian((colors[i + 1] + colors[i]) / 2, dist / 2)());
            }
          }
        }

        // subtract 360 degree if the hue value higher
        if (hue > 360) hue -= 360;

        const newHSL = new HSL({ h: hue });

        // generate palette with saturation and lightness for a certain hue value
        const palette = this.generateS(sn, newHSL).map((hsl) =>
          this.generateL(ln, hsl)
        );
        let flatPalette = [];
        palette.map((hsls) => (flatPalette = flatPalette.concat(hsls)));

        // update saturation and lightness
        const siblings = _colors.filter(
          (d) => d.h >= hue - deltaH && d.h <= hue + deltaH
        );

        if (siblings.length) {
          // find the most unlike color compared to siblings colors from flatPalette
          flatPalette = flatPalette.map((p) => {
            // calculate min distance to siblings
            const dist = siblings
              .map((s) =>
                Math.sqrt((s.s - p.s) * (s.s - p.s) + (s.l - p.l) * (s.l - p.l))
              )
              .reduce((a, b) => Math.min(a, b));
            return {
              color: p,
              dist: dist,
            };
          });
          // find max from min distances
          max = 0;
          hue = null;
          flatPalette.forEach((d) => {
            if (d.dist > max) {
              max = d.dist;
              hue = d.color;
            }
          });
        } else {
          // if there is no siblings, select color from palette randomly
          const rnd = randomInt(0, flatPalette.length);
          hue = flatPalette[rnd];
        }

        _colors.push(hue);
      } else {
        // get random color
        // TODO: generate randomly with normally distributed values of saturation and lightness
        _colors.push(this.randomHSL({ s: 70, l: 60 }));
      }
    }

    return _colors.slice(baseColors.length);
  }

  generateSequenceWithShuffle(n) {
    const _colors = this.generateSequence(n);
    return shuffle(_colors);
  }
}

// generate random integer number from interval [l, u)
export const randomInt = (l, u) => {
  const r = Math.random();
  return Math.floor(l + r * (u - l));
};

// random permutation of array
export const shuffle = (array) => {
  let currentIndex = array.length,
    temporaryValue,
    randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};
