import { useNavigate } from 'react-router-dom';
import Logo from '/src/i/pickaxe.svg';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';

const SSO = () => {
  const history = useNavigate();
  const { isLoading, isAuthenticated, error, getIdTokenClaims } = useAuth0();
  const [userHasProjectAccess, setUserProjectAccess] = useState(false);
  const [defaultTenantName, setDefaultTenantName] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const redirectReferrer = window.localStorage.getItem('sso_redirect');

  const contacts = {
    Telemundo: 'seamus.czprathstein@nbcuni.com',
  };

  useEffect(() => {
    const setItem = window.localStorage.setItem.bind(window.localStorage);

    const ssoOptions = props => {
      const { token, user, roles, permissions, message } = props;

      if (message) {
        setErrorMessage(message);
      }

      if (user) {
        let defaultTenant = user.tenants.find(tenant => tenant.slug === user.tenant);

        // check for referrer url and find tenant id
        if (redirectReferrer && !redirectReferrer.includes('/')) {
          const projectFromUrl = redirectReferrer?.split('/')[1];

          defaultTenant = user.tenants.find(tenant => tenant.projects.find(project => project.slug === projectFromUrl));
        }

        setDefaultTenantName(defaultTenant?.name);

        // Check if a user has access to at least one project
        if (defaultTenant?.projects?.length > 0) {
          setItem('token', token);
          setItem('user', user.firstName);
          setItem('email', user.email);
          setItem(
            'roles',
            roles.map(v => v.name)
          );
          setItem('permissions', JSON.stringify(permissions));
          setItem('defaultTenantID', defaultTenant.id);
          setItem('defaultTenantSlug', defaultTenant.slug);
          const defaultProject = defaultTenant.projects.find(project => project.slug === defaultTenant.slug);
          setItem('currentProjectId', defaultProject.id);

          // FIXME: It's needs to get fixed
          // window.analytics.identify(user.id, {
          //   email: user.email,
          //   first_name: user.firstName,
          //   last_name: user.lastName,
          //   tenant: user.tenant,
          // });

          window.localStorage.removeItem('sso_redirect');
          history(redirectReferrer);
        } else {
          setUserProjectAccess(true);
        }
      } else {
        console.log(message);
      }
    };

    const callAPI = async () => {
      const claims = await getIdTokenClaims();

      fetch(`${import.meta.env.VITE_APP_REST_URI}/auth0/login`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          id_token: claims.__raw,
        }),
      })
        .then(res => res.json())
        .then(res => ssoOptions(res));
    };

    if (isAuthenticated && !isLoading) {
      callAPI();
    }
  }, [isLoading, isAuthenticated, getIdTokenClaims, setUserProjectAccess, history, redirectReferrer]);

  // Generic authentication error
  const errorMessages = errorMessage => {
    let params = new URLSearchParams(document.location.search);
    let error_param = params.get('error');

    return (
      <>
        <h2 className="sso-title">There was a problem logging in.</h2>
        <p>Error: {error_param || errorMessage}</p>
        <p>
          Please return to the{' '}
          <a href="/" className="text-white">
            login
          </a>{' '}
          page and try again.
        </p>
      </>
    );
  };

  // Authenticated user without project access
  const accessMessage = () => {
    const contact = contacts[defaultTenantName];

    return (
      <>
        <h2 className="sso-title">Welcome To Pickaxe.</h2>
        <p>
          Sorry, it looks like you haven't been granted access to any projects yet. <br />
          Please contact your admin to get access:
        </p>
        <p>
          <b>{contact}</b>
        </p>
      </>
    );
  };

  return (
    <main className="grid grid-cols-1 md:grid-cols-2">
      <div className="flex flex-col items-center justify-center flex-1 order-first h-64 md:h-screen">
        <div className="max-w-sm">
          <a href="/" className="inline-block mb-5">
            <img src={Logo} alt="Pickaxe" className="w-60" />
          </a>
          <h1 className="text-5xl font-bold text-black font-mslab_700">It's your data. We just make it useful.</h1>
        </div>
        <div className="w-full max-w-sm mt-auto mb-4 text-base text-black">
          @ {new Date().getFullYear()} Pickaxe Foundry, Inc
        </div>
      </div>
      <section className="flex flex-col items-center text-center justify-center relative bg-[#fe544b]">
        <div className="z-10">
          {userHasProjectAccess && accessMessage()}
          {error && errorMessages()}
          {errorMessage && errorMessages(errorMessage)}
          {!error && !errorMessage && !userHasProjectAccess && (
            <div className="text-center">
              <p className="text-lg text-white font-mslab_700">Redirecting...</p>
            </div>
          )}
        </div>
      </section>
    </main>
  );
};

export default SSO;
