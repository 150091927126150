import LoaderChart from './LoaderChart';
import LoaderForm from './LoaderForm';
import LoaderItemsList from './LoaderItemsList';
import { Skeleton } from './skeleton';

export const SkeletonTypes = {
  chart: 'chart',
  metric: 'metric',
  table: 'table',
  listItems: 'listItems',
  form: 'form',
};

const MetricSkeleton = ({ options }) => (
  <div className="space-y-2">
    {!options?.metricValue?.hideFriendlyName && (
      <Skeleton className="h-4 w-[100px]" />
    )}
    <div className="flex items-center space-x-2">
      <Skeleton className="h-12 w-[120px]" />
      {options?.trendValue?.visible !== false && (
        <>
          <Skeleton className="w-6 h-6" />
          <Skeleton className="h-6 w-[60px]" />
        </>
      )}
    </div>
  </div>
);

const skeleton_map = {
  chart: <LoaderChart />,
  metric: <MetricSkeleton />,
  listItems: <LoaderItemsList />,
  form: <LoaderForm />,
  default: (
    <div className="flex flex-col space-y-4">
      <Skeleton className="w-3/4 h-[20px] rounded-full" />
      <Skeleton className="w-1/2 h-[20px] rounded-full" />
      <Skeleton className="w-full h-[330px] rounded-lg" />
    </div>
  ),
};

const WidgetSkeleton = ({ type, options }) => {
  if (type === 'metric') {
    return <MetricSkeleton options={options} />;
  }
  
  const skeleton = skeleton_map[type];
  return skeleton ? skeleton : skeleton_map.default;
};

export { WidgetSkeleton };
