import { createAction } from "redux-actions";

export const LOGIN_REQUEST = "app/Dashboard/LOGIN_REQUEST";
export const LOGIN_SUCCESS = "app/Dashboard/LOGIN_SUCCESS";

export const loginRequest = createAction(LOGIN_REQUEST);
export const loginSuccess = createAction(LOGIN_SUCCESS);

export const FORGOT_PASSWORD_REQUEST = "app/Dashboard/FORGOT_PASSWORD_REQUEST";
export const RESET_PASSWORD_REQUEST = "app/Dashboard/FORGOT_PASSWORD_REQUEST";

export const forgotPasswordRequest = createAction(FORGOT_PASSWORD_REQUEST);
export const resetPasswordRequest = createAction(RESET_PASSWORD_REQUEST);

export const LOGOUT_REQUEST = "app//LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "app//LOGOUT_SUCCESS";

export const logoutRequest = createAction(LOGOUT_REQUEST);
export const logoutSuccess = createAction(LOGOUT_SUCCESS);

export const LOAD_DATA_REQUEST = "app//LOAD_DATA_REQUEST";
export const LOAD_DATA_SUCCESS = "app//LOAD_DATA_SUCCESS";

export const loadDataRequest = createAction(LOAD_DATA_REQUEST);
export const loadDataSuccess = createAction(LOAD_DATA_SUCCESS);

export const TOGGLE_INSIGHTS = "app//TOGGLE_INSIGHTS";
export const toggleInsights = createAction(TOGGLE_INSIGHTS);

export const BOARD_INFO = "app//BOARD_INFO";
export const boardInfo = createAction(BOARD_INFO);

export const DASHBOARD_GROUPS = "app//DASHBOARD_GROUPS";
export const dashboardGroups = createAction(DASHBOARD_GROUPS);
