const themeConfig = {
  color: [
    '#fc6d52',
    '#fcd239',
    '#15c8de',
    '#15de85',
    '#f3108e',
    '#87c544',
    '#b85a5c',
    '#86c499',
    '#c164a5',
    '#757ec9',
    '#c08e4a',
    '#9f50d4',
    '#607e3f',
    '#c9b0bb',
    '#65757d',
  ],
  backgroundColor: 'rgba(255,255,255,0)',
  grid: {
    left: '0',
    right: '0',
    bottom: '10%',
    containLabel: true,
  },
  title: {
    textStyle: {
      color: '#666666',
    },
    subtextStyle: {
      color: '#999999',
    },
  },
  line: {
    itemStyle: {
      borderWidth: '2',
    },
    lineStyle: {
      width: '3',
    },
    symbolSize: '7',
    symbol: 'circle',
    smooth: true,
  },
  radar: {
    itemStyle: {
      borderWidth: '2',
    },
    lineStyle: {
      width: '3',
    },
    symbolSize: '7',
    symbol: 'circle',
    smooth: true,
  },
  bar: {
    itemStyle: {
      barBorderWidth: '1',
      barBorderColor: '#f2f2f2',
    },
  },
  pie: {
    itemStyle: {
      borderWidth: '1',
      borderColor: '#f2f2f2',
    },
  },
  scatter: {
    itemStyle: {
      borderWidth: '1',
      borderColor: '#f2f2f2',
    },
  },
  boxplot: {
    itemStyle: {
      borderWidth: '1',
      borderColor: '#f2f2f2',
    },
  },
  parallel: {
    itemStyle: {
      borderWidth: '1',
      borderColor: '#f2f2f2',
    },
  },
  sankey: {
    itemStyle: {
      borderWidth: '1',
      borderColor: '#f2f2f2',
    },
  },
  funnel: {
    itemStyle: {
      borderWidth: '1',
      borderColor: '#f2f2f2',
    },
  },
  gauge: {
    itemStyle: {
      borderWidth: '1',
      borderColor: '#f2f2f2',
    },
  },
  candlestick: {
    itemStyle: {
      color: '#fc6d52',
      color0: 'transparent',
      borderColor: '#fc6d52',
      borderColor0: '#15c8de',
      borderWidth: '1',
    },
  },
  graph: {
    itemStyle: {
      borderWidth: '1',
      borderColor: '#f2f2f2',
    },
    lineStyle: {
      width: '1',
      color: '#cccccc',
    },
    symbolSize: '7',
    symbol: 'circle',
    smooth: true,
    color: [
      '#fc6d52',
      '#fcd239',
      '#15c8de',
      '#15de85',
      '#f3108e',
      '#87c544',
      '#b85a5c',
      '#86c499',
      '#c164a5',
      '#757ec9',
      '#c08e4a',
      '#9f50d4',
      '#607e3f',
      '#c9b0bb',
      '#65757d',
    ],
    label: {
      color: '#ffffff',
    },
  },
  map: {
    itemStyle: {
      areaColor: '#eeeeee',
      borderColor: '#999999',
      borderWidth: 0.5,
    },
    label: {
      color: '#28544e',
    },
    emphasis: {
      itemStyle: {
        areaColor: 'rgba(34,195,170,0.25)',
        borderColor: '#22c3aa',
        borderWidth: 1,
      },
      label: {
        color: '#349e8e',
      },
    },
  },
  geo: {
    itemStyle: {
      areaColor: '#eeeeee',
      borderColor: '#999999',
      borderWidth: 0.5,
    },
    label: {
      color: '#28544e',
    },
    emphasis: {
      itemStyle: {
        areaColor: 'rgba(34,195,170,0.25)',
        borderColor: '#22c3aa',
        borderWidth: 1,
      },
      label: {
        color: '#349e8e',
      },
    },
  },
  categoryAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: '#f2f2f2',
      },
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: '#f2f2f2',
      },
    },
    axisLabel: {
      fontFamily: 'Museo Slab 500',
      fontWeight: 300,
      show: true,
      color: '#818181',
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ['#f2f2f2'],
      },
    },
    splitArea: {
      show: true,
      areaStyle: {
        color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
      },
    },
  },
  valueAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: '#f2f2f2',
      },
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: '#f2f2f2',
      },
    },
    axisLabel: {
      fontFamily: 'Museo Slab 500',
      fontWeight: 300,
      show: true,
      color: '#818181',
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ['#f2f2f2'],
      },
    },
    splitArea: {
      show: true,
      areaStyle: {
        color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
      },
    },
  },
  logAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: '#f2f2f2',
      },
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: '#f2f2f2',
      },
    },
    axisLabel: {
      show: true,
      color: '#818181',
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ['#f2f2f2'],
      },
    },
    splitArea: {
      show: true,
      areaStyle: {
        color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
      },
    },
  },
  timeAxis: {
    axisLine: {
      show: true,
      lineStyle: {
        color: '#f2f2f2',
      },
    },
    axisTick: {
      show: true,
      lineStyle: {
        color: '#f2f2f2',
      },
    },
    axisLabel: {
      fontFamily: 'Museo Slab 500',
      fontWeight: 300,
      show: true,
      color: '#818181',
    },
    splitLine: {
      show: true,
      lineStyle: {
        color: ['#f2f2f2'],
      },
    },
    splitArea: {
      show: true,
      areaStyle: {
        color: ['rgba(250,250,250,0.05)', 'rgba(200,200,200,0.02)'],
      },
    },
  },
  toolbox: {
    iconStyle: {
      borderColor: '#818181',
    },
    emphasis: {
      iconStyle: {
        borderColor: '#666666',
      },
    },
  },
  legend: {
    textStyle: {
      fontFamily: 'Museo Slab 500',
      color: '#818181',
    },
  },
  tooltip: {
    textStyle: {
      fontFamily: 'Museo Slab 500',
    },
    axisPointer: {
      lineStyle: {
        color: '#818181',
        width: '1',
      },
      crossStyle: {
        color: '#818181',
        width: '1',
      },
    },
  },
  timeline: {
    lineStyle: {
      color: '#fc6d52',
      width: 1,
    },
    itemStyle: {
      color: '#fc6d52',
      borderWidth: '1',
    },
    controlStyle: {
      color: '#fc6d52',
      borderColor: '#ffffff',
      borderWidth: 0.5,
    },
    checkpointStyle: {
      color: '#fc6d52',
      borderColor: '#ffffff',
    },
    label: {
      color: '#818181',
    },
    emphasis: {
      itemStyle: {
        color: '#fc6d52',
      },
      controlStyle: {
        color: '#fc6d52',
        borderColor: '#ffffff',
        borderWidth: 0.5,
      },
      label: {
        color: '#818181',
      },
    },
  },
  visualMap: {
    color: ['#fc6d52', '#fcd239', '#15c8de'],
  },
  dataZoom: {
    backgroundColor: 'rgba(255,255,255,0)',
    dataBackgroundColor: 'rgba(222,222,222,1)',
    fillerColor: 'rgba(114,230,212,0.25)',
    handleColor: '#cccccc',
    handleSize: '100%',
    textStyle: {
      color: '#999999',
    },
  },
  markPoint: {
    label: {
      color: '#ffffff',
    },
    emphasis: {
      label: {
        color: '#ffffff',
      },
    },
  },
};

export const PickaxeEchartsTheme = themeConfig;
export default themeConfig;
