export const BASEURL = 'https://api-production.pickaxe.ai/api';

export const HEADERS = (token) => {
  const headers = {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json',
  };
  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }
  return headers;
};
/**
 * All widgets disconnected from the endpoint but contains title to be displayed
 * by the WidgetHeader, should be listed here
 */
export const allowedWidgetWithoutURL = ['KeyFindings'];
