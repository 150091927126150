import { AlertCircle } from 'lucide-react';
import React from 'react';

const ChartsNoDataAvailable = () => {
  return (
    <div
      className={`h-64 w-full flex flex-col items-center justify-center 
    rounded-lg p-4 text-center`}
    >
      <AlertCircle className="text-gray-400 mb-4" size={48} />
      <h3 className="text-lg font-semibold text-gray-600 mb-2">No data available</h3>
    </div>
  );
};

export default ChartsNoDataAvailable;
