// TODO: this is old store that will be removed

import { create } from 'zustand';
import BoardConfig from './TempBoardConfig.json';
import { cloneDeep } from 'lodash';

const loadBoardConfig = () => {
  const savedConfig = localStorage.getItem('boardConfig');
  return savedConfig ? JSON.parse(savedConfig) : cloneDeep(BoardConfig);
};

const useWidgetBuilderStore = create((set, get) => ({
  boardConfig: loadBoardConfig(),
  setBoardConfig: config => {
    const clonedConfig = cloneDeep(config);
    localStorage.setItem('boardConfig', JSON.stringify(clonedConfig));
    set({ boardConfig: clonedConfig });
  },

  currentBoard: null,
  setCurrentBoard: slug => {
    set(state => {
      if (state.boardConfig.boards && state.boardConfig.boards.length > 0) {
        let board;

        if (slug) {
          board = state.boardConfig.boards.find(b => b.slug === slug);
        }

        if (!board) {
          board = cloneDeep(state.boardConfig.boards[0]);
        }

        return { currentBoard: board };
      }
      return {};
    });
  },

  currentWidgetConfig: {},
  setCurrentWidgetConfig: config => set({ currentWidgetConfig: config }),

  currentElementConfig: {},
  setCurrentElementConfig: config => set({ currentElementConfig: config }),

  selectedElementId: null,
  setSelectedElementId: id => set({ selectedElementId: id }),

  findAndSetCurrentWidgetConfig: id =>
    set(state => {
      const { boardConfig } = state;

      const findWidgetById = widgets => {
        for (const widget of widgets) {
          if (widget.id === id) {
            return widget;
          }
          if (widget.children) {
            const found = findWidgetById(widget.children);
            if (found) {
              return found;
            }
          }
        }
        return null;
      };

      if (id && boardConfig.boards) {
        for (const board of boardConfig.boards) {
          const foundWidget = findWidgetById(board.widgets);
          if (foundWidget) {
            return { currentWidgetConfig: foundWidget };
          }
        }
      }

      return { currentWidgetConfig: {} };
    }),

  findAndSetCurrentElementConfig: id =>
    set(state => {
      const { currentWidgetConfig } = state;
      const element = recursiveSearch([currentWidgetConfig], id);

      if (element) {
        return { currentElementConfig: element };
      }
      return { currentElementConfig: {} };
    }),

  moveElementUp: () => {
    set(state => {
      const { currentWidgetConfig, selectedElementId } = state;

      if (!currentWidgetConfig || !selectedElementId) {
        console.error('currentWidgetConfig or selectedElementId is undefined');
        return {};
      }

      function moveElementUpRecursive(elements) {
        for (let i = 0; i < elements.length; i++) {
          if (elements[i].id === selectedElementId && i > 0) {
            [elements[i], elements[i - 1]] = [elements[i - 1], elements[i]];
            return true;
          }
          if (elements[i].children && moveElementUpRecursive(elements[i].children)) {
            return true;
          }
        }
        return false;
      }

      let newCurrentWidgetConfig = cloneDeep(currentWidgetConfig);
      if (newCurrentWidgetConfig.children) {
        moveElementUpRecursive(newCurrentWidgetConfig.children);
      }

      let newBoardConfig = cloneDeep(state.boardConfig);

      const updateWidgetInBoard = widgets => {
        for (let i = 0; i < widgets.length; i++) {
          if (widgets[i].id === newCurrentWidgetConfig.id) {
            widgets[i] = newCurrentWidgetConfig;
            return true;
          }
          if (widgets[i].children) {
            const updated = updateWidgetInBoard(widgets[i].children);
            if (updated) return true;
          }
        }
        return false;
      };

      if (newBoardConfig && Array.isArray(newBoardConfig.boards)) {
        newBoardConfig.boards.forEach(board => {
          updateWidgetInBoard(board.widgets);
        });
      } else {
        console.error('newBoardConfig.boards is undefined or not an array');
      }
      return {
        currentWidgetConfig: newCurrentWidgetConfig,
        boardConfig: newBoardConfig,
      };
    });
  },

  moveElementDown: () => {
    set(state => {
      const { currentWidgetConfig, selectedElementId } = state;

      if (!currentWidgetConfig || !selectedElementId) {
        console.error('currentWidgetConfig or selectedElementId is undefined');
        return {};
      }

      let newCurrentWidgetConfig = cloneDeep(currentWidgetConfig);

      function moveElementDownRecursive(elements) {
        for (let i = 0; i < elements.length; i++) {
          if (elements[i].children) {
            const result = moveElementDownRecursive(elements[i].children);
            if (result) return true;
          }
          if (elements[i].id === selectedElementId && i < elements.length - 1) {
            [elements[i], elements[i + 1]] = [elements[i + 1], elements[i]];
            return true;
          }
        }
        return false;
      }

      if (newCurrentWidgetConfig.children) {
        moveElementDownRecursive(newCurrentWidgetConfig.children);
      }

      let newBoardConfig = cloneDeep(state.boardConfig);

      const updateWidgetInBoard = widgets => {
        for (let i = 0; i < widgets.length; i++) {
          if (widgets[i].id === newCurrentWidgetConfig.id) {
            widgets[i] = newCurrentWidgetConfig;
            return true;
          }
          if (widgets[i].children) {
            const updated = updateWidgetInBoard(widgets[i].children);
            if (updated) return true;
          }
        }
        return false;
      };

      if (newBoardConfig && Array.isArray(newBoardConfig.boards)) {
        newBoardConfig.boards.forEach(board => {
          updateWidgetInBoard(board.widgets);
        });
      }

      return {
        currentWidgetConfig: newCurrentWidgetConfig,
        boardConfig: newBoardConfig,
      };
    });
  },

  deleteWidgetElement(elementId) {
    useWidgetBuilderStore.setState(state => {
      const { boardConfig, currentWidgetConfig } = state;
      let updatedBoardConfig = { ...boardConfig };
      let updatedCurrentWidgetConfig = { ...currentWidgetConfig };

      function recursiveDelete(elements, id) {
        for (let i = 0; i < elements.length; i++) {
          if (elements[i].id === id) {
            elements.splice(i, 1);
            return true;
          }
          if (elements[i].children) {
            const isDeleted = recursiveDelete(elements[i].children, id);
            if (isDeleted) return true;
          }
        }

        return false;
      }

      updatedBoardConfig.boards.forEach(board => {
        board.widgets.forEach(widget => {
          if (recursiveDelete([widget], elementId)) {
            updatedCurrentWidgetConfig = widget;
          }
        });
      });

      return {
        boardConfig: updatedBoardConfig,
        currentWidgetConfig: updatedCurrentWidgetConfig,
      };
    });
  },

  updateWidget: (widgetId, newElementConfig, currentBoardId) => {
    useWidgetBuilderStore.setState(state => {
      let { boardConfig } = state;

      // Create a deep copy of the boardConfig using structuredClone
      let updatedBoardConfig = structuredClone(boardConfig);

      // Find the index of the current board
      const boardIndex = updatedBoardConfig.boards.findIndex(board => board.id === currentBoardId);

      if (boardIndex === -1) {
        console.error(`Board with id ${currentBoardId} not found.`);
        return state;
      }

      let isUpdated = false;

      // Recursive function to update the widget
      const recursiveUpdateWidget = elements => {
        return elements.map(element => {
          if (element.id === widgetId) {
            isUpdated = true;
            return newElementConfig;
          } else if (element.children) {
            element.children = recursiveUpdateWidget(element.children);
          }
          return element;
        });
      };

      // Update the widgets of the current board
      updatedBoardConfig.boards[boardIndex].widgets = updatedBoardConfig.boards[boardIndex].widgets.map(widget => {
        widget = recursiveUpdateWidget([widget]);
        return widget[0];
      });

      if (!isUpdated) {
        console.error(`Widget with id ${widgetId} not found in board ${currentBoardId}.`);
        return state;
      }
      // Update localStorage
      localStorage.setItem('boardConfig', JSON.stringify(updatedBoardConfig));

      // Return the new state
      return {
        ...state,
        boardConfig: updatedBoardConfig,
      };
    });
  },

  updateWidgetElement(elementId, newElementConfig, currentBoardId) {
    useWidgetBuilderStore.setState(state => {
      const { boardConfig } = state;
      let updatedBoardConfig = cloneDeep(boardConfig);

      const boardIndex = updatedBoardConfig.boards.findIndex(board => board.id === currentBoardId);
      if (boardIndex === -1) {
        console.error(`Board with id ${currentBoardId} not found.`);
        return;
      }

      function recursiveUpdate(elements) {
        for (let i = 0; i < elements.length; i++) {
          if (elements[i].id === elementId) {
            elements[i] = { ...elements[i], ...newElementConfig };
            return true;
          }
          if (elements[i].children) {
            const updated = recursiveUpdate(elements[i].children);
            if (updated) return true;
          }
        }
        return false;
      }

      const boardWidgets = updatedBoardConfig.boards[boardIndex].widgets;
      let isUpdated = recursiveUpdate(boardWidgets);

      if (!isUpdated) {
        console.error(`Element with id ${elementId} not found in board ${currentBoardId}.`);
        return;
      }

      return {
        ...state,
        boardConfig: updatedBoardConfig,
      };
    });
  },
}));

function recursiveSearch(elements, id) {
  for (const element of elements) {
    if (element.id === id) {
      return element;
    }
    if (element.children) {
      const found = recursiveSearch(element.children, id);
      if (found) {
        return found;
      }
    }
  }
  return null;
}

export default useWidgetBuilderStore;
