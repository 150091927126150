import React from 'react';

import { FormControl, FormField, FormItem, FormLabel } from '../form';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue, Button } from './../index';

const ChartOptionSelectFormField = React.memo(({ control, name, labelText, options, keyValue, keyName, format }) => {
  const renderProps = React.useMemo(
    () =>
      ({ field }) => {
        return (
          <FormItem>
            <FormLabel className="mb-0 text-xs font-bold">{labelText}</FormLabel>
            <Select onValueChange={field.onChange} defaultValue={field.value}>
              <FormControl>
                <SelectTrigger>
                  <SelectValue placeholder="Select an option" />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                <SelectItem key="none" value="null" className="flex items-center justify-between">
                  <div className="flex space-x-2">None</div>
                </SelectItem>
                {options.map((option, index) => (
                  <SelectItem
                    key={index}
                    value={!keyValue ? option : option[keyValue]}
                    className="flex items-center justify-between"
                  >
                    <div className="flex space-x-2">
                      {format ? format(option) : !keyName ? option : option[keyName]}
                    </div>
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </FormItem>
        );
      },
    [labelText, options, control, name]
  );

  return <FormField control={control} name={name} render={renderProps} />;
});

export { ChartOptionSelectFormField };
