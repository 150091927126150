import React from 'react';

const LoaderItemsList = () => {
  return (
    <div role="status" className=" animate-pulse">
      <div className="flex items-start space-x-2">
        <div className="h-8 bg-gray-200 rounded-md mb-2 mr-2 w-1/2 mb-2 "></div>
        <div className="h-8 bg-gray-200 rounded-md mb-2 w-32 mb-2"></div>
      </div>
      <div className="flex items-start space-x-2">
        <div className="h-8 bg-gray-200 rounded-md mb-2 w-60 mr-2 "></div>
        <div className="h-8 bg-gray-200 rounded-md mb-2 w-32 mb-2"></div>
      </div>
      <div className="flex items-start space-x-2">
        <div className="h-8 bg-gray-200 rounded-md mb-2 mr-2 w-4/5"></div>
        <div className="h-8 bg-gray-200 rounded-md mb-2 w-32 mb-2"></div>
      </div>
      <div className="flex items-start space-x-2">
        <div className="h-8 bg-gray-200 rounded-md mb-2 mr-2 w-1/2"></div>
        <div className="h-8 bg-gray-200 rounded-md mb-2 w-32 mb-2"></div>
      </div>
      <div className="flex items-start space-x-2">
        <div className="h-8 bg-gray-200 rounded-md mb-2 mr-2 w-1/4"></div>
        <div className="h-8 bg-gray-200 rounded-md mb-2 w-32 mb-2"></div>
      </div>
      <div className="flex items-start space-x-2">
        <div className="h-8 bg-gray-200 rounded-md mb-2 mr-2 w-56"></div>
        <div className="h-8 bg-gray-200 rounded-md mb-2 w-32 mb-2"></div>
      </div>
    </div>
  );
};

export default LoaderItemsList;
