import React, { memo } from 'react';
import useGlobalStore from '/src/stores/globalStore';
import AdminTools from '/src/components/AdminTools';
import { NavEditBoard } from '/src/components/Header';
import { withRouter } from '../../../utils/routerUtils';
import NavMain from '../NavMain';

const Nav = () => {
  const boardEditMode = useGlobalStore(state => state.boardEditMode);

  return (
    <header className="max-w-screen-xl mx-auto my-6 text-gray-600">
      {boardEditMode ? <NavEditBoard /> : <NavMain />}
      <AdminTools />
    </header>
  );
};

export default withRouter(memo(Nav));
