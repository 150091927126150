import React from 'react';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@widget-builder/src/components/ui';

const BoardSelector = ({ boards, selected, onChange }) => {
  if (!boards || boards.length === 0) return null;

  // Find the currently selected board based on `selected` or default to the first board
  const selectedBoard = boards.find(board => board.slug === selected) || boards[0];

  return (
    <Select onValueChange={onChange} value={selectedBoard.slug} className="main-nav">
      <SelectTrigger className="w-auto p-0 text-3xl text-gray-700 border-0 border-none md:text-lg font-mslab_700 focus:ring-0">
        <SelectValue>{selectedBoard ? selectedBoard.title : 'Select Board'}</SelectValue>
      </SelectTrigger>
      <SelectContent className="text-gray-700 bg-white rounded-none border-slate-100 font-mslab_700">
        <SelectGroup>
          {boards.map(board => (
            <SelectItem
              key={board.id}
              value={board.slug}
              showCheck={false}
              className="pl-2 text-xl text-left cursor-pointer md:text-sm hover:text-orange-400 focus:text-orange-400 focus:bg-white"
            >
              {board.title}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

export default BoardSelector;
