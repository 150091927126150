import React from 'react';

import { FormControl, FormField, FormItem, FormLabel } from '../form';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../select';

const ChartOptionMultiSelectFormField = React.memo(({ control, name, labelText, options }) => {
  const renderProps = React.useMemo(
    () =>
      ({ field }) => {
        const handleValueChange = values => {
          field.onChange(values);
        };

        return (
          <FormItem>
            <FormLabel className="mb-0 font-bold">{labelText}</FormLabel>
            <Select onValueChange={handleValueChange} defaultValue={field.value} multiple={true}>
              <FormControl>
                <SelectTrigger className="h-8">
                  <SelectValue placeholder="" className="" />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                {options.map(option => (
                  <SelectItem key={option} value={option} className="flex items-center justify-between ">
                    <div className="flex space-x-2 ">{option}</div>
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </FormItem>
        );
      },
    [labelText, options]
  );

  return <FormField control={control} name={name} render={renderProps} />;
});

export { ChartOptionMultiSelectFormField };
