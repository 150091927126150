import React from 'react';
import { NavLink } from 'react-router-dom';
import { X } from 'lucide-react';
import ProjectSelector from './ProjectSelector';
import BoardSelector from './BoardSelector';
import NavigationLinks from './NavigationLinks';
import UserMenu from './UserMenu';

const MobileMenu = ({ isOpen, onClose, projects, boards, handleProjectChange, handleBoardChange, handleLinkClick }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex flex-col p-5 overflow-y-auto bg-white">
      <div className="flex justify-end">
        <button onClick={onClose} className="text-gray-700 hover:text-black focus:outline-none" aria-label="Close menu">
          <X className="w-8 h-8" />
        </button>
      </div>

      <NavLink to="/" onClick={onClose} className="fixed top-6 left-4">
        <img
          className="w-6"
          src="https://dashboard.pickaxe.ai/static/media/axe.1da3ba0a47e0a3ae3d091f0bb40b44cb.svg"
          alt="Logo"
        />
      </NavLink>

      <div className="mt-10 mb-4">
        <ProjectSelector projects={projects} onChange={handleProjectChange} />
      </div>

      <div className="mb-4">
        <BoardSelector boards={boards} onChange={handleBoardChange} />
      </div>

      <nav className="flex flex-col mb-4 space-y-4">
        <NavigationLinks onLinkClick={handleLinkClick} />
      </nav>

      <div className="mx-auto mt-auto">
        <UserMenu />
      </div>
    </div>
  );
};

export default MobileMenu;
