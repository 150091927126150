export const SAMPLE_DATA = [
  {
    annotations: {
      metrics: {
        sample: {
          friendlyName: 'Sample Data',
        },
      },
    },
    data: [
      { timestamp: '2024-05-15T00:00:00.000Z', sample: 10 },
      { timestamp: '2024-05-16T00:00:00.000Z', sample: 20 },
      { timestamp: '2024-05-17T00:00:00.000Z', sample: 30 },
      { timestamp: '2024-05-18T00:00:00.000Z', sample: 40 },
      { timestamp: '2024-05-19T00:00:00.000Z', sample: 50 },
      { timestamp: '2024-05-20T00:00:00.000Z', sample: 60 },
      { timestamp: '2024-05-21T00:00:00.000Z', sample: 70 },
      { timestamp: '2024-05-22T00:00:00.000Z', sample: 80 },
      { timestamp: '2024-05-23T00:00:00.000Z', sample: 90 },
      { timestamp: '2024-05-24T00:00:00.000Z', sample: 100 },
    ],
  },
];

/**
 * Extract the data that comes from the runQueries
 * @param {*} data the data coming from the runQueries
 * @param {*} source the source specified as target
 */
export function extractDataFromSource(data, source) {
  try {
    let dataFound = data?.runQueries?.find(
      query => query.id.toString() === source.dataSource.queryId.toString()
    ).result;
    if (dataFound) {
      return JSON.parse(dataFound);
    } else return {};
  } catch (exception) {
    console.log(exception);
  }
}

/**
 * Returns true/false if the queries aren't empty
 * @param {*} queries
 */
export function hasQueries(queries) {
  return queries !== undefined && queries?.length > 0;
}

export function hasData(data) {
  return data && data.flatMap(d => d.data).length > 0;
}

export const extractDatasets = data => {
  const dataset = [];
  (data || []).forEach(d => {
    const currentDataset = {
      queryId: d.id,
      dimensions: [{ name: 'timestamp', friendlyName: 'Timestamp' }],
      source: [],
    };

    ['dimensions', 'metrics'].forEach(sourceKey => {
      if (d.annotations && d.annotations[sourceKey]) {
        Object.keys(d.annotations[sourceKey]).forEach(key => {
          const newDimension = {
            name: key,
            friendlyName: d.annotations[sourceKey][key].friendlyName,
          };
          currentDataset.dimensions.push(newDimension);
        });
      }
    });

    currentDataset.source = d.data;
    dataset.push(currentDataset);
  });
  return dataset;
};
