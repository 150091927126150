import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { getWidgetQueries } from '../../../dashboard/src/api/WidgetApi';
import { SAMPLE_DATA } from '../utils/DataUtils';
import { widgetCreate, widgetRead, widgetUpdate, widgetDelete, widgetMovePosition } from './widgetBuilderApi';

export const useReadWidget = (widgetID, onSuccess) => {
  const tenantID = +window.localStorage.getItem('defaultTenantID');

  return useQuery({
    queryKey: ['widget', tenantID, widgetID],
    queryFn: () => widgetRead({ tenantID, id: +widgetID }),
    onSuccess,
    enabled: !!tenantID && !!widgetID, // Only run if both tenantID and widgetID are provided
  });
};

export const useUpdateWidget = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ widgetID, input }) => {
      const tenantID = window.localStorage.getItem('defaultTenantID');
      const { id, createdAt, ...validInput } = input;

      return widgetUpdate({ tenantID, widgetID, input: validInput });
    },
    onSuccess: (data, variables) => {
      const { widgetID } = variables;
      const tenantID = +window.localStorage.getItem('defaultTenantID');

      queryClient.invalidateQueries({ queryKey: ['widget', tenantID, widgetID] });
    },
    onError: error => {
      console.error('Error updating widget:', error);
    },
  });
};

export const useCreateWidget = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ boardConfigID, input }) => {
      const tenantID = +window.localStorage.getItem('defaultTenantID');
      return widgetCreate({ boardConfigID, tenantID, input });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: 'widget-config' });
    },
    onError: error => {
      console.error('Error creating widget:', error);
    },
  });
};

export const useDeleteWidget = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: widgetID => {
      const tenantID = +window.localStorage.getItem('defaultTenantID');
      return widgetDelete({ tenantID, widgetID });
    },
    onSuccess: () => {
      // queryClient.invalidateQueries({ queryKey: 'widget-config' });
      queryClient.invalidateQueries(['dashboard-config']);
    },
    onError: error => {
      console.error('Error deleting widget:', error);
    },
  });
};

export const useMoveWidgetPosition = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({ widgetID, position }) => {
      const tenantID = +window.localStorage.getItem('defaultTenantID');
      return widgetMovePosition({ tenantID, widgetID, position });
    },
    onSuccess: () => {
      // queryClient.invalidateQueries({ queryKey: 'widget-config' });
      queryClient.invalidateQueries(['dashboard-config']);
    },
    onError: error => {
      console.error('Error deleting widget:', error);
    },
  });
};

/**
 * Hook used to retrieve data from the widget queries
 * @param {*} component
 * @param {*} queries
 * @returns
 */
export const useWidgetQueriesData = (component, queries) => {
  const { isLoading, isError, data, refetch } = useQuery({
    queryKey: [component, queries.map(q => q.id)],
    queryFn: async () => {
      try {
        const response = await getWidgetQueries(
          {
            queries,
          },
          true
        );

        return response.runQueries
          ? response.runQueries.flatMap(resultData => ({
              id: resultData.id,
              ...JSON.parse(resultData.result),
            }))
          : [];
      } catch (e) {
        console.error('Failed to fetch or parse data:', e);
        return SAMPLE_DATA;
      }
    },
    retry: false,
    enabled: queries.length > 0,
  });

  return { isLoading, isError, data, refetch };
};

export const useGetFetchQuery = name => {
  const queryClient = useQueryClient();

  return queryClient.getQueryData(name);
};
