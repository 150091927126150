/**
 * Persists a cookie
 * @param {*} cname Cookie name
 * @param {*} cvalue Cookie value
 * @param {*} exMinutes Expiration time in minutes
 */
export function setCookie(cname, cvalue, exMinutes) {
  let d = new Date();
  d.setTime(d.getTime() + exMinutes * 60 * 1000);
  let expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

/**
 * Get the cookie value
 * @param {*} cname Cookie name
 */
export function getCookie(cname) {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  // always return null as default
  return null;
}

/**
 * Performs logout when the session has expired
 * @param {*} navigate
 */
export function logout(navigate) {
  window.localStorage.removeItem('token');
  window.localStorage.removeItem('defaultTenantID');
  window.localStorage.removeItem('defaultTenantSlug');
  window.localStorage.removeItem('roles');
  window.localStorage.removeItem('user');
  window.localStorage.removeItem('email');

  navigate('/login');
}
