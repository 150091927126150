import dayjs from 'dayjs';

export const WidgetConfigVersion = {
  PRODUCTION: 'production',
  DEVELOPMENT: 'development',
};

const isRoot = document.location.pathname === '/';

export const getFirstBoard = currentProject => {
  if (!currentProject) return null;
  const boards = currentProject.boardsV2;
  return boards?.length ? boards.sort((a, b) => a.position - b.position)[0] : null;
};

export const getBoardByName = (projectConfig, boardName) =>
  projectConfig?.boardsV2?.find(board => board.slug === boardName) || null;

export const getWidgetConfig = boardConfig => {
  const localVersion = localStorage.getItem('version');
  return (
    boardConfig?.configs?.find(c => c.version === localVersion) ||
    boardConfig?.configs?.find(c => c.version === WidgetConfigVersion.PRODUCTION) ||
    null
  );
};

export const GetActiveBoardObject = data => {
  if (isRoot) {
    return data.projects[0]?.boardsV2?.[0] || null;
  } else {
    const project = GetActiveProjectObject(data);
    const boardSlug = window.location.pathname.split('/')[2];
    return project.boardsV2?.find(p => p.slug === boardSlug) || project.boardsV2?.[0] || null;
  }
};

export const GetActiveProjectObject = data => {
  const projectSlug = window.location.pathname.split('/')[1];
  return isRoot ? data.projects[0] : data.projects.find(p => p.slug === projectSlug) || data.projects[0];
};

export const formatDate = (value, options) => dayjs(value).format(options?.format || 'DD/MM/YYYY');

export const formatMetric = (metricValue, { ...opts } = {}) => {
  if (opts.type === 'time') {
    switch (opts.timeFormat) {
      case 'seconds':
        return `${metricValue} s`;
      case 'm':
        return `${Math.floor(metricValue / 60)} min`;
      case 'h':
        return `${Math.floor(metricValue / 3600)} h`;
      case 'hms': {
        const seconds = Number(metricValue);
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = Math.floor(seconds % 60);
        return `${h > 0 ? `${h} h, ` : ''}${m > 0 ? `${m} min, ` : ''}${s > 0 ? `${s} sec` : ''}`;
      }
      default:
        return `${metricValue} s`;
    }
  } else {
    const params = {
      style: opts.type || 'decimal',
      ...(opts.currency && { currency: opts.currency }),
      maximumFractionDigits: opts.decimalPoints !== undefined ? opts.decimalPoints : 2,
    };

    const shortFormats = [
      { value: 1e12, symbol: 't' },
      { value: 1e9, symbol: 'b' },
      { value: 1e6, symbol: 'm' },
      { value: 1e3, symbol: 'k' },
    ];

    const format = shortFormats.find(format => metricValue >= format.value);

    if (format && !opts.showFullValue) {
      const formatted = new Intl.NumberFormat(opts.locale || 'en-US', params).format(metricValue / format.value);
      const parts = formatted.match(/([\D]*)([\d.,]+)([\D]*)/);
      return `${parts[1]}${parts[2]}${format.symbol}${parts[3]}`;
    } else {
      return new Intl.NumberFormat(opts.locale || 'en-US', params).format(metricValue);
    }
  }
};

export const findBoardConfigIdBySlug = (boardConfig, slug) => {
  if (!boardConfig || !boardConfig.configs || !Array.isArray(boardConfig.configs)) {
    throw new Error('Invalid boardConfig structure.');
  }

  const config = boardConfig.configs.find(config => config.slug === slug);

  return config ? config.id : null;
};
