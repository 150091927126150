import React from 'react';

const LoaderChart = () => {
  return (
    <div className="animate-pulse">
      <div className="flex space-x-4 items-baseline m-6">
        <div className="flex-1 h-24 bg-gray-200 rounded"></div>
        <div className="flex-1 h-48 bg-gray-200 rounded"></div>
        <div className="flex-1 h-64 bg-gray-300 rounded"></div>
        <div className="flex-1 h-48 bg-gray-200 rounded"></div>
        <div className="flex-1 h-50 bg-gray-200 rounded"></div>
      </div>
    </div>
  );
};

export default LoaderChart;
