import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, NavLink } from 'react-router-dom';
import useGlobalStore from '/src/stores/globalStore';
import { useQuery } from '@tanstack/react-query';
import { getDashboard } from '/src/api/DashboardApi';
import { withRouter } from '../../../utils/routerUtils';

// import useWidgetBuilderStore from '@widget-builder/src/stores/useWidgetBuilderStore';
import { Menu } from 'lucide-react';
import { Separator, Skeleton } from '@widget-builder/src/components/ui';

import ProjectSelector from './ProjectSelector';
import BoardSelector from './BoardSelector';
import NavigationLinks from './NavigationLinks';
import UserMenu from './UserMenu';
import MobileMenu from './MobileMenu';

import logo from '/src/i/axe.svg';

const NavMain = () => {
  const { currentProjectObj, currentBoardObj } = useGlobalStore();
  const { data, isLoading } = useQuery({
    queryKey: ['dashboard-config'],
    queryFn: getDashboard,
    retry: true,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const navigate = useNavigate();
  const { project, board } = useParams();

  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedBoard, setSelectedBoard] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (data && data.projects) {
      const initialProject = project
        ? data.projects.find(proj => proj.slug === project)
        : currentProjectObj || data.projects[0];

      if (initialProject) {
        setSelectedProject(initialProject);

        const initialBoard = board
          ? initialProject.boardsV2.find(b => b.slug === board)
          : currentBoardObj || initialProject.boardsV2?.[0];

        setSelectedBoard(initialBoard);
      }
    }
  }, [data, project, board, currentProjectObj, currentBoardObj]);

  const handleProjectChange = projectSlug => {
    const newProject = data.projects.find(proj => proj.slug === projectSlug);
    setSelectedProject(newProject);

    if (newProject) {
      const newBoard = newProject.boardsV2?.[0] || null;
      setSelectedBoard(newBoard);
      navigate(`/${projectSlug}/${newBoard ? newBoard.slug : ''}`);
    }
  };

  const handleBoardChange = boardSlug => {
    const newBoard = selectedProject?.boardsV2.find(b => b.slug === boardSlug);
    setSelectedBoard(newBoard);
    navigate(`/${selectedProject.slug}/${boardSlug}`);
  };

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  if (isLoading) {
    return (
      <div className="container relative max-w-screen-xl px-5 py-2 mx-auto bg-white">
        <div className="hidden md:flex md:items-center md:justify-between">
          <Skeleton className="w-20 h-8 mr-2" />
          <Skeleton className="w-32 h-8 mr-2" />
          <Skeleton className="w-32 h-8 mr-2" />
          <Skeleton className="w-48 h-8 mr-2" />
          <Skeleton className="w-10 h-10 ml-auto rounded-full" />
          <Skeleton className="w-48 h-8 ml-2" />
        </div>

        <div className="fixed top-0 left-0 z-50 flex items-center justify-between w-full p-4 bg-white md:hidden">
          <Skeleton className="w-10 h-10" />
          <Skeleton className="w-10 h-10 rounded-full" />
        </div>
      </div>
    );
  }

  return (
    <div className="container relative w-full max-w-screen-xl px-5 py-2 mx-auto bg-white">
      <div className="hidden md:items-center md:justify-between md:flex">
        <div className="flex items-center">
          <NavLink key="home" to="/">
            <img className="w-5 mr-5" src={logo} alt="Logo" />
          </NavLink>

          <ProjectSelector projects={data.projects} selected={selectedProject?.slug} onChange={handleProjectChange} />

          <Separator orientation="vertical" className="inline h-8 mx-5" />

          <BoardSelector
            boards={selectedProject?.boardsV2}
            selected={selectedBoard?.slug}
            onChange={handleBoardChange}
          />

          {selectedProject?.boardsV2?.length > 0 && <Separator orientation="vertical" className="inline h-8 mx-5" />}

          <NavigationLinks onLinkClick={handleLinkClick} />
        </div>

        <div>
          <UserMenu />
        </div>
      </div>

      <div className="fixed top-0 left-0 z-50 flex items-center justify-between w-full p-4 bg-white md:hidden">
        <NavLink to="/" onClick={handleClose}>
          <img className="w-6" src={logo} alt="Logo" />
        </NavLink>

        <button
          onClick={handleOpen}
          className="text-gray-500 cursor-pointer hover:text-black focus:outline-none"
          aria-label="Open menu"
        >
          <Menu className="w-10 h-10" />
        </button>
      </div>

      <MobileMenu
        isOpen={isOpen}
        onClose={handleClose}
        projects={data.projects}
        boards={selectedProject?.boardsV2}
        handleProjectChange={handleProjectChange}
        handleBoardChange={handleBoardChange}
        handleLinkClick={handleLinkClick}
      />
    </div>
  );
};

export default withRouter(NavMain);
