import React, { useState, useEffect } from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '@widget-builder/src/components/ui';

import LogOutButton from '/src/components/Auth/LogOutButton';
import TenantsMenu from '/src/components/Header/NavMain/TenantsMenu';

import { User, Settings, Users, LogOut, ChevronDown, Building } from 'lucide-react';

import { useNavigate } from 'react-router-dom';
import useGlobalStore from '../../../stores/globalStore';

const UserMenu = () => {
  const navigate = useNavigate();
  const [showTenantsMenu, setShowTenantsMenu] = useState(false);

  const handleNavigation = path => {
    navigate(path);
  };

  const openTenantsMenu = () => {
    setShowTenantsMenu(true);
  };

  const closeTenantsMenu = () => {
    setShowTenantsMenu(false);
  };

  const userName = useGlobalStore(state => state.userName);
  const isAdmin = useGlobalStore(state => state.isAdmin);

  // Helper function to get initials
  const getInitials = name => {
    if (!name) return 'G';
    return name
      .split(' ')
      .map(part => part.charAt(0).toUpperCase())
      .join('')
      .slice(0, 2);
  };

  // Helper function to capitalize words
  const capitalizeWords = str => {
    if (!str) return 'Guest';
    return str
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser && storedUser !== userName) {
      useGlobalStore.getState().updateUserInfo({ name: storedUser });
    }
  }, [userName]);

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger className="flex items-center bg-transparent border-0 cursor-pointer focus:ring-0 focus:outline-none">
          <Avatar className="w-10 h-10 bg-orange-100 md:w-8 md:h-8">
            <AvatarFallback className="text-black bg-gray-100 font-mslab_700">{getInitials(userName)}</AvatarFallback>
          </Avatar>
          <span className="ml-2 text-xl md:text-sm font-mslab_700">{capitalizeWords(userName)}</span>
          <ChevronDown className="w-4 h-4 opacity-50" />
        </DropdownMenuTrigger>
        <DropdownMenuContent className="w-56 text-gray-700 rounded-none">
          <DropdownMenuGroup>
            {/* <DropdownMenuItem
              className="flex items-center cursor-pointer font-mslab_700 hover:text-orange-400 focus:text-orange-400 focus:bg-white text-md"
              onClick={() => handleNavigation('/settings/profile')}
            >
              <User className="mr-2 text-gray-400" />
              <span>Profile</span>
            </DropdownMenuItem> */}
            <DropdownMenuItem
              className="flex items-center cursor-pointer font-mslab_700 hover:text-orange-400 focus:text-orange-400 focus:bg-white text-md"
              onClick={() => handleNavigation('/settings/configuration')}
            >
              <Settings className="w-4 h-4 mr-2 text-gray-400" />
              <span>Settings</span>
            </DropdownMenuItem>
          </DropdownMenuGroup>

          {isAdmin && (
            <DropdownMenuGroup>
              <DropdownMenuItem
                className="flex items-center cursor-pointer font-mslab_700 hover:text-orange-400 focus:text-orange-400 focus:bg-white text-md"
                onClick={() => handleNavigation('/settings/users')}
              >
                <Users className="w-4 h-4 mr-2 text-gray-400" />
                <span>Users</span>
              </DropdownMenuItem>
            </DropdownMenuGroup>
          )}

          <DropdownMenuGroup>
            <DropdownMenuItem
              className="flex items-center cursor-pointer font-mslab_700 hover:text-orange-400 focus:text-orange-400 focus:bg-white text-md"
              onClick={openTenantsMenu}
            >
              <Building className="w-4 h-4 mr-2 text-gray-400" />
              <span>Tenants</span>
            </DropdownMenuItem>
          </DropdownMenuGroup>

          <DropdownMenuSeparator className="my-2 border-t border-gray-200" />

          <DropdownMenuItem
            className="flex items-center cursor-pointer font-mslab_700 hover:text-orange-400 focus:text-orange-400 focus:bg-white text-md"
            onClick={() => handleNavigation('/logout')}
          >
            <LogOut className="w-4 h-4 mr-2 text-gray-400" />
            <LogOutButton />
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <TenantsMenu isOpen={showTenantsMenu} onClose={closeTenantsMenu} />
    </>
  );
};

export default UserMenu;
