import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import useGlobalStore from '/src/stores/globalStore';

const useAuth = (email, password) => {
  const { setUser } = useGlobalStore();

  return useQuery({
    queryKey: ['auth'],
    queryFn: async () =>
      await axios
        .post(process.env.VITE_APP_REST_URI + '/auth/login', {
          email: email,
          password: password,
        })
        .then(response => {
          if (response.status === 200) {
            setUser(response.data);

            const defaultTenant = response.data.user.tenants.find(tenant => tenant.slug === response.data.user.tenant);

            window.localStorage.setItem('token', response.data.token);
            window.localStorage.setItem('defaultTenantID', defaultTenant.id);
            window.localStorage.setItem('defaultTenantSlug', defaultTenant.slug);
            window.localStorage.setItem(
              'roles',
              response.data.roles.map(role => role.name)
            );
            window.localStorage.setItem('tenants', JSON.stringify(response.data.user.tenants));
            window.localStorage.setItem('user', response.data.user.firstName);
            window.localStorage.setItem('email', response.data.user.email);
          }
        })
        .catch(response => {
          if (!response.ok) {
            return {
              isError: true,
              errorMessage: response.response.data.error,
            };
          }
        }),
    enabled: false,
    retry: false,
    refetchOnWindowFocus: false,
  });
};

export default useAuth;
