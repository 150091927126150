import React, { useEffect, useRef, useMemo } from 'react';
import ReactECharts from 'echarts-for-react';
import useWidgetBuilderStore from '@dashboard/stores/useWidgetBuilderStore';
import * as echarts from 'echarts/core';
import { ErrorComponent } from '../../ui';
import { extractDatasets, SAMPLE_DATA } from '../../../utils/DataUtils';
import { WidgetSkeleton, SkeletonTypes } from '../widgetSkeleton';
import ChartsNoDataAvailable from './ChartsNoDataAvailable';
import { useWidgetQueriesData } from '../../../api/useWidgetQueries';
import { PickaxeEchartsTheme } from './PickaxeEchartsTheme';

echarts.registerTheme('pickaxe', PickaxeEchartsTheme);

const EchartsChart = ({ id, type, className, children, component, queries = [], options }) => {
  const { boardConfig, selectedElementId, updateWidgetElement, setCurrentElementConfig } = useWidgetBuilderStore(
    state => state
  );

  const echartsRef = useRef(null);

  let { isLoading, isError, data } = useWidgetQueriesData(component, queries);

  const datasets = useMemo(() => {
    const datasets = [...extractDatasets(data || SAMPLE_DATA)];
    return datasets;
  }, [data]);

  const createDefaultOptions = () => {
    const defaultOptions = {};

    if (datasets === undefined) {
      return null;
    }

    const mergedOptions = {
      ...defaultOptions,
      ...options,
      dataset: datasets,
    };

    return mergedOptions;
  };

  const chartOptions = createDefaultOptions();

  useEffect(() => {
    const handleResize = () => echartsRef.current?.getEchartsInstance().resize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const isEditMode = window.location.href.includes('/builder/');
    if (isEditMode && !isLoading && !isError && selectedElementId === id) {
      setCurrentElementConfig({
        id: selectedElementId,
        component,
        className,
        type,
        props: {
          options: { ...chartOptions },
        },
      });
    }
  }, [updateWidgetElement, isLoading, isError, selectedElementId, boardConfig, chartOptions]);

  if (isLoading) return <WidgetSkeleton type={SkeletonTypes.chart} />;
  if (isError) return <ErrorComponent error={errors.join(', ')} />;

  if (chartOptions === null) return null;

  if (!chartOptions.dataset.flatMap(d => d.source).length === 0) return <ChartsNoDataAvailable />;

  return (
    <div id={id} draggable={true} type={type} className={className}>
      <ReactECharts
        ref={echartsRef}
        option={chartOptions}
        style={{ height: '400px', width: '100%' }}
        notMerge={true}
        lazyUpdate={true}
        theme="pickaxe"
      />
      {children}
    </div>
  );
};

export { EchartsChart };
