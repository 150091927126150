import { BASEURL } from '../utils/constants';

export const absolute = (uri) => {
  return uri.includes('http') ? uri : `${BASEURL}${uri}`;
};

function headers(token) {
  const headers = {
    'Cache-Control': 'no-cache',
    'Content-Type': 'application/json',
  };

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  return headers;
}

export function post(uri, { token, data }) {
  return fetch(absolute(uri), {
    method: 'POST',
    headers: headers(token),
    body: JSON.stringify(data),
  });
}

export function get(uri, token) {
  return fetch(absolute(uri), {
    method: 'GET',
    headers: headers(token),
  });
}

export function put(uri, { token, data }) {
  return fetch(absolute(uri), {
    method: 'PUT',
    headers: headers(token),
    body: JSON.stringify(data),
  });
}

export function delete_(uri, token) {
  return fetch(absolute(uri), {
    method: 'DELETE',
    headers: headers(token),
  });
}
