import axios from 'axios';
import * as queries from '/src/components/GraphQL';

import { toast } from 'react-toastify';

export const getDashboard = async ({ ...variables }) => {
  const response = await axios.post(import.meta.env.VITE_APP_GRAPHQL_URI, {
    query: queries.QUERY_DASHBOARD_CONFIG,
    variables: {
      tenantID: window.localStorage.getItem('defaultTenantID') || window.localStorage.getItem('defaultTenantID'),
      ...variables,
      version: window.localStorage.getItem('version') || 'production',
    },
  });

  if (response.status !== 200) {
    toast.error('Request or Response Failed', {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    throw new Error('Error');
  }

  return response.data.data;
};

export const getQuery = async ({ queryKey }) => {
  const [, queryID] = queryKey;
  const response = await axios.post(import.meta.env.VITE_APP_GRAPHQL_URI, {
    query: queries.WIDGET_LOAD_QUERY,
    variables: {
      tenantID: +window.localStorage.getItem('defaultTenantID') || window.localStorage.getItem('defaultTenantID'),
      queryID: queryID,
    },
  });

  if (response.status !== 200) {
    throw new Error('Error');
  }

  return response.data.data.loadQuery;
};
