import useGlobalStore from "/src/stores/globalStore";

// import { Link } from 'react-router-dom'
import ButtonAddWidget from "/src/components/Elements/Buttons/ButtonAddWidget";
import { withRouter } from "../../../utils/routerUtils";

const NavEditBoard = () => {
  const { setBoardEditMode, currentProjectObj, currentBoardObj } =
    useGlobalStore();

  return (
    <header className="max-w-screen-xl p-4 mx-5 my-5 text-gray-600 lg:mx-0">
      <div className="flex">
        <div className="flex text-gray-900 title-font md:mb-0">
          <div className="max-w-screen-xl mx-auto mt-6 text-center">
            <ButtonAddWidget
              projectID={currentProjectObj.id}
              boardID={currentBoardObj.id}
              boardConfigID={
                currentBoardObj.configs.find((v) => v.version === "production")
                  .id
              }
              widgetID="new"
            />
          </div>

          {/* <div className="mr-6">
            <input
              id="boardName"
              name="boardName"
              type="text"
              placeholder={boardObject.title}
              className="form-input"
            />
          </div> */}
          {/* <div className="mr-6">
            <input
              id="boardName"
              name="boardName"
              type="text"
              placeholder="Position"
              className="w-24 form-input"
            />
          </div> */}
          {/* <div className="mr-6">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                className=""
                defaultChecked={false}
                onChange={() => alert('publish')}
              />
              <span className="ml-2">Published</span>
            </label>
          </div>
          <div className="mr-6">
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                className=""
                defaultChecked={false}
                onChange={() => alert('live')}
              />
              <span className="ml-2">Live</span>
            </label>
          </div>
          <div className="mr-6">
            <button className="btn">Publish Board</button>
          </div> */}

          {/* <div className="mr-6">
            <Link className="p-2 btn" to="/editor">
              + Add Widget
            </Link>
          </div> */}
        </div>

        <div className="flex justify-center ml-auto text-base">
          <button onClick={() => setBoardEditMode(false)}>
            <span className="relative text-2xl text-black outline-none hover:text-orange top-1 right-1">
              <i className="text-black fa-regular fa-xmark-large hover:text-orange"></i>
            </span>
          </button>
        </div>
      </div>
    </header>
  );
};

export default withRouter(NavEditBoard);
